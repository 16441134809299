<template>
    <v-snackbar
        :value="value"
        bottom
        right
        timeout="-1"
        color="primary"
        transition="scroll-x-reverse-transition"
        rounded
        :vertical="isMobile"
        :width="isMobile ? '100%' : ''"
    >
        <v-card max-width="300" height="80" elevation="0" color="transparent">
            <v-card-title> {{ title }} </v-card-title>
            <v-card-subtitle>
                {{ subtitle }}
            </v-card-subtitle>
        </v-card>
        <template v-slot:action="{ attrs }">
            <v-btn depressed color="primary" @click="emit('refresh')">
                {{ btnText }}
            </v-btn>
            <v-btn
                class="close-icon-wrapper"
                icon
                v-bind="attrs"
                @click="emit('close')"
            >
                <CloseIcon />
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
import CloseIcon from "@/assets/prondo/close-light.svg"
import { useResponsive } from "@/composables/responsive"
const { isMobile } = useResponsive()
withDefaults(
    defineProps<{
        title?: string
        subtitle?: string
        btnText?: string
        value: boolean
    }>(),
    {
        btnText: "Update",
        title: "An Update is available",
        subtitle: "Please update to the latest version of the app to continue"
    }
)
const emit = defineEmits(["refresh", "close"])
</script>
