import { DirectiveOptions } from "vue"
import moment from "moment"
const DATE_FORMAT = "MMM DD, YYYY"
const DATE_TIME_FORMAT = "MMM DD, YYYY, h:mm a"
export const dateFormat: DirectiveOptions = {
    bind: (el, binding) => {
        el.innerText = moment(binding.value).format(DATE_FORMAT)
    },
    update: (el, binding) => {
        el.innerText = moment(binding.value).format(DATE_FORMAT)
    }
}
export const dateTimeFormat: DirectiveOptions = {
    bind: (el, binding) => {
        el.innerText = moment(binding.value).format(DATE_TIME_FORMAT)
    },
    update: (el, binding) => {
        el.innerText = moment(binding.value).format(DATE_TIME_FORMAT)
    }
}
