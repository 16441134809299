export class EmailCampaign {
    static readonly CREATE_CAMPAIGN = "/campaigns"
    static readonly CAMPAIGNS = "/campaigns"
    static readonly TEST_EMAIL = "campaignMessages/test"
    static readonly PREVIEW_EMAIL = "campaignMessages/preview"
    static readonly STAGES_COUNTER = "/recipients/stagesCounter"
    static readonly STATUS_COUNTER = "/campaigns/statusesCounter"
    static readonly CAMPAIGN_MESSAGES = "/campaignMessages"
    static readonly CAMPAIGN_INBOXES = "/campaignInboxes"
    static readonly CUSTOM_ATTRIBUTES = "/customAttributes"
    static readonly GET_ATTRIBUTES = "/messages/attributes"
    static readonly CAMPAIGN_RECIPIENT = "/campaignRecipients"
    static readonly CAMPAIGN_RECIPIENT_MESSEGES = "/campaignRecipientMessages"
    static readonly CREATE_RECIPIENT = "/lists/export/emailService"
}
