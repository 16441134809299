import axios from "axios"
import { getEmailToken } from "@/helpers/localStorage"
import Nprogress from "nprogress"

const Axios = axios.create({
    baseURL: (window as any).CONFIG.VUE_APP_EMAIL_SERVICE_BASE_URL,
    timeout: +(window as any).CONFIG.VUE_APP_HTTP_REQUEST_TIMEOUT,
    headers: {
        "X-Application": (window as any).CONFIG.VUE_APP_APP_ID,
        "Content-Type": "application/json"
    }
})

const authInterceptor = (config: any) => {
    Nprogress.start()
    const token = getEmailToken()
    if (token) {
        config.headers["Authorization"] = `Token ${token}`
    }
    return config
}

Axios.interceptors.request.use(authInterceptor)

Axios.interceptors.response.use((res) => {
    Nprogress.done()
    return res
})

export { Axios }
