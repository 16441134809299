import { GetterTree } from "vuex"
import { BaseState as RootState } from "@/store/Types/BaseState"
import { BaseState } from "./BaseState"

const getters: GetterTree<BaseState, RootState> = {
    getDatabases(state) {
        return state.databases || []
    },
    isDatabaseLoading(state) {
        return state.databaseLoading
    },
    getTotalDabases(state) {
        return state.totalDatabases
    },
    getDatabaseUpdateAvailable(state) {
        return state.databaseUpdateAvailable
    }
}

export default getters
