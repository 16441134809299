import { useEventSource } from "@/composables/EventSource"
import { StreamEventType } from "@prondo/stream-events"
import { TSnakType } from "@/helpers/types"
import { EventSourceMessages } from "@/constants/EventSourceMessages"
import { useToasttify } from "@/plugins/Toastification"
import store from "@/store"
import { ref } from "vue"

type Progress = {
    processed: number
    succeeded: number
    total: number
}
export const INIT_SSE = () => {
    const baseURL = (window as any).CONFIG.VUE_APP_BACKEND_API_URL
    const importData = ref<{
        lastImportId: string | number
        progress: Progress
    }>({
        lastImportId: 0,
        progress: {
            processed: 0,
            succeeded: 0,
            total: 0
        }
    })
    const exportData = ref<{
        lastExportId: string | number
        progress: Progress
    }>({
        lastExportId: 0,
        progress: {
            processed: 0,
            succeeded: 0,
            total: 0
        }
    })
    const { subscribe, disconnect, connect } = useEventSource({
        sseUrl: `${baseURL}/sse`
    })
    connect()
    // Import / export
    subscribe(StreamEventType.WORKSPACE_EXPORT_PROGRESS, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EXPORT_PROGRESS) {
            const { ToastId } = useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_EXPORT_PROGRESS +
                    event.data.taskId
                }`,
                isCompleted: isEventComplete(event.data.progress),
                title: isEventComplete(event.data.progress)
                    ? "Database export completed"
                    : "Database export in progress",
                type: "SUCCESS",
                progress: {
                    ...event.data.progress
                }
            })
            exportData.value.progress = event.data.progress
            exportData.value.lastExportId = ToastId
        }
    })
    subscribe(StreamEventType.WORKSPACE_EXPORT_COMPLETED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EXPORT_COMPLETED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: `${EventSourceMessages["WORKSPACE_EXPORT_COMPLETED"]}`
            })
            useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_EXPORT_PROGRESS +
                    event.data.taskId
                }`,
                title: "Database export completed",
                type: "SUCCESS",
                progress: {
                    ...exportData.value.progress,
                    processed: exportData.value.progress.total
                },
                timeout: 3000
            })
            store.dispatch("getNotificationCount")
        }
    })
    subscribe(StreamEventType.WORKSPACE_EXPORT_FAILED, (event) => {
        if (event.type === StreamEventType.WORKSPACE_EXPORT_FAILED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.ERROR,
                message: `${EventSourceMessages["WORKSPACE_EXPORT_FAILED"]}`
            })
            useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_EXPORT_PROGRESS +
                    event.data.taskId
                }`,
                title: "Database export failed",
                type: "ERROR",
                progress: {
                    ...exportData.value.progress
                }
            })
            store.dispatch("getNotificationCount")
        }
    })
    subscribe(StreamEventType.WORKSPACE_IMPORT_PROGRESS, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_IMPORT_PROGRESS) {
            useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_IMPORT_PROGRESS +
                    event.data.taskId
                }`,
                type: "SUCCESS",
                title: isEventComplete(event.data.progress)
                    ? "Database import completed"
                    : "Database import in progress",
                progress: {
                    ...event.data.progress
                }
            })
            importData.value.progress = event.data.progress
            importData.value.lastImportId =
                StreamEventType.WORKSPACE_IMPORT_PROGRESS
        }
    })
    subscribe(StreamEventType.WORKSPACE_IMPORT_COMPLETED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_IMPORT_COMPLETED) {
            useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_IMPORT_PROGRESS +
                    event.data.taskId
                }`,
                title: "Database import completed",
                type: "SUCCESS",
                isCompleted: true,
                progress: {
                    ...importData.value.progress,
                    processed: importData.value.progress.total
                },
                snakbarType: "static",
                timeout: 3000
            })
            store.commit("databaseStore/SET_DATABASE_UPDATE_AVAILABLE", true)
            store.dispatch("getNotificationCount")
        }
    })
    subscribe(StreamEventType.WORKSPACE_IMPORT_FAILED, (event) => {
        if (event.type === StreamEventType.WORKSPACE_IMPORT_FAILED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.ERROR,
                message: `${EventSourceMessages["WORKSPACE_IMPORT_FAILED"]}`
            })
            useToasttify({
                id: `${
                    StreamEventType.WORKSPACE_IMPORT_PROGRESS +
                    event.data.taskId
                }`,
                title: "Database import failed",
                type: "ERROR",
                snakbarType:
                    importData.value.progress.total <= 0 ? "static" : "dynamic",
                progress: {
                    ...importData.value.progress
                }
            })
            store.dispatch("getNotificationCount")
        }
    })
    // end Import / export
    subscribe(StreamEventType.WORKSPACE_EMAILS_VERIFY_PROGRESS, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_VERIFY_PROGRESS) {
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_VERIFY_PROGRESS}`,
                title: isEventComplete(event.data.progress)
                    ? "Email Verify completed"
                    : "Email Verify in progress...",
                isCompleted: isEventComplete(event.data.progress)
                    ? true
                    : false,
                type: "SUCCESS",
                snakbarType: "static"
            })
        }
    })
    subscribe(StreamEventType.WORKSPACE_EMAILS_VERIFY_COMPLETED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_VERIFY_COMPLETED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: `${EventSourceMessages["WORKSPACE_EMAILS_VERIFY_COMPLETED"]}`
            })
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_VERIFY_PROGRESS}`,
                title: "Database verify completed",
                type: "SUCCESS",
                snakbarType: "static",
                isCompleted: true,
                timeout: 3000
            })
            store.dispatch("getNotificationCount")
            store.commit("databaseStore/SET_DATABASE_UPDATE_AVAILABLE", true)
        }
    })
    subscribe(StreamEventType.WORKSPACE_EMAILS_VERIFY_FAILED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_VERIFY_FAILED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.ERROR,
                message: `${EventSourceMessages["WORKSPACE_EMAILS_VERIFY_FAILED"]}`
            })
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_VERIFY_PROGRESS}`,
                title: "Email verify failed",
                type: "ERROR",
                snakbarType: "static",
                isCompleted: true
            })
            store.dispatch("getNotificationCount")
        }
    })

    subscribe(StreamEventType.WORKSPACE_EMAILS_FIND_PROGRESS, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_FIND_PROGRESS) {
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_FIND_PROGRESS}`,
                type: "SUCCESS",
                title: isEventComplete(event.data.progress)
                    ? "Email find completed"
                    : "Email find in progress..",
                snakbarType: "static",
                isCompleted: isEventComplete(event.data.progress) ? true : false
            })
        }
    })
    subscribe(StreamEventType.WORKSPACE_EMAILS_FIND_COMPLETED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_FIND_COMPLETED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: `${EventSourceMessages["WORKSPACE_EMAILS_FIND_COMPLETED"]}`
            })
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_FIND_PROGRESS}`,
                title: "Email find completed",
                type: "SUCCESS",
                snakbarType: "static",
                isCompleted: true,
                timeout: 3000
            })
            store.commit("databaseStore/SET_DATABASE_UPDATE_AVAILABLE", true)
            store.dispatch("getNotificationCount")
        }
    })
    subscribe(StreamEventType.WORKSPACE_EMAILS_FIND_FAILED, (event) => {
        console.log({ event })
        if (event.type === StreamEventType.WORKSPACE_EMAILS_FIND_FAILED) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.ERROR,
                message: `${EventSourceMessages["WORKSPACE_EMAILS_FIND_FAILED"]}`
            })
            useToasttify({
                id: `${StreamEventType.WORKSPACE_EMAILS_FIND_PROGRESS}`,
                title: "Email find failed",
                type: "ERROR",
                snakbarType: "static",
                isCompleted: true
            })
            store.dispatch("getNotificationCount")
        }
    })

    subscribe(StreamEventType.INBOX_BLOCKED, (event) => {
        console.log({ event })
        store.commit("SET_SNAKBAR", {
            show: true,
            type: TSnakType.ERROR,
            message: `${EventSourceMessages["INBOX_BLOCKED"]}`
        })
    })
    subscribe(StreamEventType.INBOX_UNAUTHORIZED, (event) => {
        console.log({ event })
        store.commit("SET_SNAKBAR", {
            show: true,
            type: TSnakType.ERROR,
            message: `${EventSourceMessages["INBOX_UNAUTHORIZED"]}`
        })
    })
    subscribe(StreamEventType.CAMPAIGN_STATUS_UPDATED, (event) => {
        console.log({ event })
        store.commit("SET_SNAKBAR", {
            show: true,
            type: TSnakType.SUCCESS,
            message: `${EventSourceMessages["CAMPAIGN_STATUS_UPDATED"]}`
        })
    })
    return { disconnect, connect }
}

const isEventComplete = (progress: Progress) => {
    return progress?.processed === progress?.total
}
