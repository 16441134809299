import { MutationTree } from "vuex"
import { BaseState } from "./BaseState"

const mutations: MutationTree<BaseState> = {
    SET_DATABASES(state, payload) {
        state.databases = payload
    },
    SET_DATABASE_LOADING(state, payload) {
        state.databaseLoading = payload
    },
    SET_TOTAL_DATABASES(state, payload) {
        state.totalDatabases = payload
    },
    SET_DATABASE_UPDATE_AVAILABLE(state, payload) {
        state.databaseUpdateAvailable = payload
    }
}
export default mutations
