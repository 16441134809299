export type ValidationCallback = (v: string) => true | string
export type ValidationRules = Array<ValidationCallback>

export const URL_REGX =
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const DOMAIN_REGEX = /^(m\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const PARTIAL_URL_REGEX =
    /^(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\/[^\s]*)?$/

interface Validator {
    /**
     * @param fieldName [string] The name of the fieldName
     * @return string | boolean
     */
    requiredRule: (fieldName: string) => ((v: any) => string | true)[]
    /**
     * @param fieldName [string] The name of the fieldName
     * @return string | boolean
     */
    isNumber: (fieldName: string) => ((v: any) => string | true)[]
    isValidEmail: (v: string) => true | string
    isValidDomain: (v: string) => true | string
    isValidUrl: (v: string) => true | string
    /**
     * @param MIN_LENGTH [number] Min length of the field
     * @param MAX_LENGTH [number] Max length of the field
     * @return string | boolean
     */
    lengthRule: (
        MIN_LENGTH?: number,
        MAX_LENGTH?: number
    ) => (v: number) => string | true
    /**
     * @param MAX_LENGTH [number] Max length of the field
     * @param FIELD_NAME [string] Name of the field
     * @return string | boolean
     */
    maximumCharacter: (
        MAX_LENGTH?: number,
        FIELD_NAME?: string
    ) => (v: string) => string | true
    EMAIL_VALIDATION_RULES: ValidationRules
}

export function useValidation(): Validator {
    const requiredRule = (fieldName: string) => {
        return [(v = "") => !!v || `${fieldName} is Required.`]
    }
    const isNumber = (fieldName: string) => {
        return [(v: any) => !isNaN(v) || `${fieldName} is not a number`]
    }
    const isValidEmail = (v: string): true | string => {
        const re =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        return re.test(v) || "E-mail must be valid"
    }
    const isValidDomain = (v: string): true | string => {
        return (
            /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(
                v
            ) || "Domain must be valid"
        )
    }
    const isValidUrl = (v: string): true | string => {
        return URL_REGX.test(v) || "Url must be valid"
    }
    const lengthRule = (MIN_LENGTH = 1, MAX_LENGTH = 200) => {
        return (v: number) =>
            (Number(v) >= MIN_LENGTH && Number(v) <= MAX_LENGTH) ||
            `Number should be between ${MIN_LENGTH} - ${MAX_LENGTH}`
    }
    const maximumCharacter = (MAX_LENGTH = 64, FIELD_NAME = "Field") => {
        return (v = "") =>
            (v && v.length <= MAX_LENGTH) ||
            `${FIELD_NAME} should be less then ${MAX_LENGTH} characters long`
    }
    const EMAIL_VALIDATION_RULES: ValidationRules = [
        (v: string): true | string => {
            return !!v || "E-mail is required"
        },
        (v: string): true | string => {
            return (
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
                "E-mail must be valid"
            )
        }
    ]
    return {
        requiredRule,
        isNumber,
        isValidEmail,
        isValidDomain,
        isValidUrl,
        lengthRule,
        maximumCharacter,
        EMAIL_VALIDATION_RULES
    }
}
