<template>
    <component
        :is="isMobile && !preserveFullscreen ? 'v-bottom-sheet' : 'v-dialog'"
        :value="props.value"
        persistent
        :max-width="props.maxWidth"
        :fullscreen="preserveFullscreen && isMobile"
        :content-class="props.contentClass"
    >
        <div class="wrapper">
            <v-btn icon @click="emit('close')" class="close" v-if="showClose">
                <CloseIcon width="25px" height="25px"
            /></v-btn>

            <slot />
        </div>
    </component>
</template>

<script lang="ts" setup>
import { useResponsive } from "@/composables/responsive"
import CloseIcon from "@/assets/prondo/close.svg"
const props = withDefaults(
    defineProps<{
        value: boolean
        maxWidth: number | string
        showClose?: boolean
        contentClass?: string
        preserveFullscreen?: boolean
    }>(),
    {
        showClose: true,
        contentClass: "",
        preserveFullscreen: false
    }
)
const emit = defineEmits<{
    (evt: "close"): boolean
}>()
const { isMobile } = useResponsive()
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}
.close {
    position: absolute;
    z-index: 999;
    right: 2%;
    margin-top: 5px;
}
</style>

<style>
.v-bottom-sheet.v-dialog {
    overflow: auto !important;
}

.v-dialog--fullscreen .v-card {
    min-height: 100% !important;
}

.v-bottom-sheet.v-dialog .v-card {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
</style>
