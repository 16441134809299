import { Module } from "vuex"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import { BaseState, Dataset, PieDataset } from "./BaseState"
import { BaseState as RootState } from "@/store/Types/BaseState"
import { TMeta } from "@/services/types/meta"

const totalDatasets: Dataset[] = [
    {
        label: "Delivered",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Delivered" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Opens",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Opens" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Clicks",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Clicks" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Replied",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Replied" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Undelivered",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Undelivered" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    }
]
const basedOnOpens: Dataset[] = [
    {
        label: "Opens",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Opens" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Clicks",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Clicks" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Replied",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Replied" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    }
]
const basedOnDelivered: Dataset[] = [
    {
        label: "Delivered",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Delivered" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Opens",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Opens" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Clicks",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Clicks" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    },
    {
        label: "Replied",
        total: 0,
        datasets: [
            { data: [], backgroundColor: "#3043EE", label: "Replied" },
            { data: [], backgroundColor: "#F3F4FE", label: "Recipients" }
        ]
    }
]
const inboxData: PieDataset = {
    labels: ["Active", "Inactive", "Unauthorized"],
    datasets: [
        {
            fill: true,
            label: "Mailboxes",
            data: [],
            backgroundColor: ["#3FCC5E", "#DDE5F6", "#F0B19D"],
            hoverOffset: 4
        }
    ]
}

export const campaignStore: Module<BaseState, RootState> = {
    namespaced: true,
    state: {
        stageOptions: [],
        inboxOptions: [],
        recipientStatus: [
            {
                text: "All",
                value: "all"
            },
            {
                text: "New",
                value: "new"
            },
            {
                text: "Running",
                value: "running"
            },
            {
                text: "Paused",
                value: "paused"
            },
            {
                text: "Completed",
                value: "completed"
            }
        ],
        interestStatus: [
            {
                text: "All",
                value: "all"
            },
            {
                text: "Interested",
                value: "interested"
            },
            {
                text: "Not Interested",
                value: "not interested"
            },
            {
                text: "None",
                value: "unknown"
            }
        ],
        campaignMessages: {
            data: [],
            meta: {} as TMeta
        },
        sequences: {
            data: [],
            meta: {} as TMeta
        },
        recipients: {
            data: [],
            meta: {} as TMeta
        },
        inboxes: {
            data: [],
            meta: {} as TMeta
        },
        campaign: undefined,
        // Charts
        totalDatasets: [...totalDatasets],
        basedOnOpens: [...basedOnOpens],
        basedOnDelivered: [...basedOnDelivered],
        inboxData,
        recipientData: [
            { label: "New", backgroundColor: "#3043ee", data: 0 },
            { label: "Running", backgroundColor: "#3FCC5E", data: 0 },
            { label: "Paused", backgroundColor: "#fe5959", data: 0 },
            { label: "Completed", backgroundColor: "#9799b3", data: 0 }
        ],
        totalInboxes: 0,
        recipientStatsLoading: false,
        // Loaders
        campaignLoading: false,
        inboxLoading: false,
        recipientLoading: false,
        campaignMessageLoading: false,
        inboxOptionsLoading: false,
        recipientStatusLoading: false,
        stageOptionsLoading: false
    },
    actions: actions,
    mutations: mutations,
    getters: getters
}
