import { Catalogues } from "./ApiConstant"
import Axios from "@/plugins/axios"
import { TCatalogue } from "./types"
import { getErrorMessage } from "@/helpers"

export class CataloguesService {
    static async getCatalogues(): Promise<Array<TCatalogue>> {
        const { data } = await Axios.get(Catalogues.ACTION_LIST)
        return data.data
    }
    static async getApplications(): Promise<{ dropcontact: any; zapier: any }> {
        const { data } = await Axios.get(Catalogues.APPLICATIONS)
        return data
    }
    static async updateApplication(apps: {
        dropcontact?: string | null
        generateZapierKey?: boolean
    }): Promise<any> {
        try {
            const { data } = await Axios.patch(Catalogues.CONNECTED_SERVICES, {
                ...apps
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
