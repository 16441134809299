import { ActionTree } from "vuex"
import { BaseState } from "./BaseState"
import { BaseState as RootState } from "@/store/Types/BaseState"
import { WorkSpaceService } from "@/services/workspace/ApiService"
import { DatabasePayload } from "@/services/workspace/types"
import * as Sentry from "@sentry/vue"

const actions: ActionTree<BaseState, RootState> = {
    async getDatabases({ commit }, payload: DatabasePayload) {
        const transaction = Sentry.startTransaction({
            op: "task",
            name: "Workspace/Database/loading"
        })
        Sentry.getCurrentHub().configureScope((scope) =>
            scope.setSpan(transaction)
        )
        commit("SET_DATABASE_LOADING", true)
        WorkSpaceService.getDatabasePaginated(payload)
            .then((e) => {
                commit("SET_DATABASES", e)
                if (payload.onComplete) {
                    payload.onComplete()
                }
                commit("SET_TOTAL_DATABASES", e.meta.total)
            })
            .catch((err) => {
                if (payload.onReject) {
                    payload.onReject()
                }
                console.log(err)
            })
            .finally(() => {
                transaction.finish()
                commit("SET_DATABASE_LOADING", false)
            })
    }
}

export default actions
