<template>
    <v-container fluid>
        <h1>Survey</h1>
        <div
            data-tf-widget="u0cRSp4c"
            data-tf-opacity="100"
            data-tf-iframe-props="title=Customer Feedback Form for Email Marketing Tool"
            data-tf-transitive-search-params
            data-tf-medium="snippet"
            :data-tf-hidden="`email=${store.state.user.email},name=${store.state.user.name},user_id=${store.state.user.id}`"
            style="width: 100%; height: 500px"
        ></div>
    </v-container>
</template>

<script setup>
import store from "../store"
import { useCdnScript } from "@/composables/useCdnScript"
useCdnScript("https://embed.typeform.com/next/embed.js", "typeform")
</script>
