import { Axios } from "@/plugins/axios/EmailService"
import LeoFetch from "@/plugins/axios"
import { getErrorMessage, getReturnURL } from "@/helpers"
import { SETTINGS } from "./constants"
import { INBOX_DATA, TetheredAccount, USER_INFO, USER_ME } from "./types"

export default class GEN_SETTINGS {
    static async patchGlobalDomain(
        domain?: string | null,
        trackingDelay?: number
    ): Promise<boolean> {
        try {
            const { status } = await Axios.put(SETTINGS.USERS_ME, {
                domain,
                trackingDelay
            })
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async updateTimezone(timezone: string): Promise<boolean> {
        try {
            const { status } = await Axios.patch(
                SETTINGS.USERS_ME + "/timezone",
                {
                    timezone
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async inboxCustomDomain(
        domain: string | null,
        inboxId: string
    ): Promise<boolean> {
        try {
            const { status } = await Axios.patch(
                `/inboxes/${inboxId}${SETTINGS.INBOX_CUSTOM_DOMAIN}`,
                {
                    domain
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async dailyLimit(
        inboxId: string,
        dailyQuota?: number
    ): Promise<boolean> {
        try {
            const { status } = await Axios.patch(
                `/inboxes/${inboxId}${SETTINGS.DAILY_QUOTA}`,
                {
                    dailyQuota
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async addSignature(
        inboxId: string,
        signature?: string
    ): Promise<boolean> {
        try {
            const { status } = await Axios.patch(
                `/inboxes/${inboxId}${SETTINGS.SIGNATURE}`,
                {
                    signature
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getAccountSettings(): Promise<USER_ME> {
        try {
            const { data } = await Axios.get(SETTINGS.USERS_ME)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getUserDetails(): Promise<USER_INFO> {
        try {
            const { data } = await LeoFetch.get(SETTINGS.USERS_ME)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getCustomerPortal(): Promise<{ result: string }> {
        try {
            const { data } = await LeoFetch.get(SETTINGS.CUSTOMER_PORTAL, {
                params: {
                    returnUrl: getReturnURL()
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    static async getInbox(inboxId: string): Promise<INBOX_DATA> {
        try {
            const { data } = await Axios.get(SETTINGS.IBOXES + "/" + inboxId)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    static async cancelSubscription(): Promise<string> {
        try {
            const { data } = await LeoFetch.patch(SETTINGS.CANCEL_SUBSCRIPTION)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    static async resumeSubscription(): Promise<string> {
        try {
            const { data } = await LeoFetch.patch(SETTINGS.RESUME_SUBSCRIPTION)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getTenants(): Promise<TetheredAccount[]> {
        try {
            const { data } = await LeoFetch.get(SETTINGS.Tenants)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
