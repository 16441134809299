import { EmailCampaign } from "./ApiConstant"
import { Axios } from "@/plugins/axios/EmailService"
import workspaceAxios from "@/plugins/axios"
import * as T from "./types"
import { getErrorMessage } from "@/helpers"
import { TMeta } from "../types/meta"
import { getEmailToken } from "@/helpers/localStorage"
import { TCampaignInbox } from "../gmail/types"

export default class EmailCampaigns {
    /**
     * Start a campaign
     * @param campaignId
     * @returns status
     *
     */
    static async startCampaign(campaignId: string): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/start`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Start a campaign
     * @param recipientId
     * @returns status
     *
     */
    static async startRecipientCampaign(
        recipientId: string,
        status: string
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGN_RECIPIENT + `/${recipientId}/status`,
                {
                    value: status
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Pause a given campaign
     * @param campaignId
     * @returns status
     *
     */
    static async pauseCampaign(campaignId: string): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/pause`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Stops a given campaign
     * @param campaignId
     * @returns status
     *
     */
    static async stopCampaign(campaignId: string): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/stop`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Create a new campaign
     * @param body :CreateCampaign
     * @returns ICreateCampaignResponse
     *
     */
    static async createCampaign(
        body: T.CreateCampaign
    ): Promise<T.ICreateCampaignResponse> {
        try {
            const { data } = await Axios.post(EmailCampaign.CREATE_CAMPAIGN, {
                ...body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Gets a new campaign by id
     * @param campaignId :CreateCampaign
     * @returns ICreateCampaignResponse
     *
     */
    static async getCampaignById(
        campaignId: string
    ): Promise<T.ICreateCampaignResponse> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CREATE_CAMPAIGN + "/" + campaignId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Delete Campaign by id
     *
     * @param campaignId :CreateCampaign
     * @returns boolean
     *
     */
    static async deleteCampaign(campaignId: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                EmailCampaign.CREATE_CAMPAIGN + "/" + campaignId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Update Campaign by id
     * @param campaignId :CreateCampaign
     * @param body :CreateCampaign
     * @returns boolean
     *
     */
    static async updateCampaign(
        campaignId: string,
        body: Partial<T.CreateCampaign>
    ): Promise<boolean> {
        try {
            const { data } = await Axios.put(
                EmailCampaign.CREATE_CAMPAIGN + "/" + campaignId,
                {
                    ...body
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Campaigns
     * @param meta : TMeta
     * @returns CampaignList
     */
    static async GetCampaigns(
        pageNum?: number,
        meta?: Partial<TMeta>,
        statuses?: string,
        searchText?: string
    ): Promise<T.CampaignList> {
        try {
            const { data } = await Axios.get(EmailCampaign.CREATE_CAMPAIGN, {
                params: {
                    pageNum,
                    ...meta,
                    statuses,
                    searchText
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Create Campaign Message >
     * Create a new sequence of campaign message
     * @param id
     * @returns CampaignList
     *
     */
    static async createCampaignMessage(
        id: number | string,
        message: Partial<T.Message>
    ): Promise<T.CampaignList> {
        try {
            const { data } = await Axios.post(
                EmailCampaign.CREATE_CAMPAIGN + "/" + id + "/messages",
                {
                    ...message
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Update Campaign message
     * @param messageId
     * @param body
     * @returns boolean
     *
     */
    static async updateCampaignMessage(
        messageId: string,
        body: Partial<T.message>
    ): Promise<boolean> {
        try {
            const { data } = await Axios.put(
                EmailCampaign.CAMPAIGN_MESSAGES + "/" + messageId,
                {
                    ...body
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async updateCampaignDelay(
        messageId: string,
        body: Partial<T.message>
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGN_MESSAGES + "/" + messageId + "/delay",
                {
                    ...body
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Delete Campaign message
     * @param messageId
     * @returns boolean
     *
     */
    static async deleteCampaignMessage(messageId: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                EmailCampaign.CAMPAIGN_MESSAGES + "/" + messageId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Campaign Messages
     * @param campaignId
     * @returns CampaignMessages
     *
     */
    static async getCampaignMessages(
        campaignId: string,
        isGroupped = false,
        meta?: Partial<TMeta>
    ): Promise<T.CampaignMessages | T.Sequence> {
        try {
            const { data } = await Axios.get(EmailCampaign.CAMPAIGN_MESSAGES, {
                params: {
                    campaignId,
                    isGroupped,
                    ...meta
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Campaign Messages by id
     * @param sequenceId
     * @param campaignId
     * @returns message
     *
     */
    static async getCampaignMessagesById(
        sequenceId: string,
        campaignId: string
    ): Promise<T.message> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGN_MESSAGES + "/" + sequenceId,
                {
                    params: {
                        campaignId
                    }
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Custom attributes for email
     * @param campaignId
     * @returns Attributes
     *
     */
    static async getCustomAttributes(
        campaignId?: string
    ): Promise<T.Attributes> {
        try {
            const { data } = await Axios.get(EmailCampaign.GET_ATTRIBUTES, {
                params: {
                    campaignId
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Custom attributes for email
     * @returns Attributes
     *
     */
    static async getCustomAttributeList(
        meta?: Partial<TMeta>,
        pageNum?: number,
        searchText?: string
    ): Promise<T.AttributeList> {
        try {
            const { data } = await Axios.get(EmailCampaign.CUSTOM_ATTRIBUTES, {
                params: { ...meta, pageNum, searchText }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Custom attributes for email
     * @param id
     * @returns Boolean
     *
     */
    static async deleteCustomAttribute(id: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                EmailCampaign.CUSTOM_ATTRIBUTES + "/" + id
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Create Custom attributes for email
     * @param body [name,displayName]
     * @returns boolean
     *
     */
    static async createAttributes(body: {
        name: string
        displayName: string
    }): Promise<boolean> {
        try {
            const { data } = await Axios.post(EmailCampaign.CUSTOM_ATTRIBUTES, {
                ...body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Recipients by campaign Id
     * @param campaignId
     * @param meta
     * @returns Recipient[]
     *
     */
    static async createCampaignRecipient(
        campaignId: string,
        recipient?: Partial<T.Recipient>
    ): Promise<T.IRecipientList> {
        try {
            const { data } = await Axios.post(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/recipients`,

                {
                    ...recipient
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get Recipients by campaign Id
     * @param campaignId
     * @param meta
     * @returns Recipient[]
     *
     */
    static async getRecipients(
        param: T.RecipientParams
    ): Promise<T.IRecipientList> {
        try {
            const params = {
                pageNum: param.pageNum,
                campaignId: param.campaignId,
                ...param.meta,
                stages: param.stages,
                searchText: param.searchText
            }
            if (param.inbox) {
                Object.assign(params, { inbox: param.inbox })
            }
            if (param.status) {
                Object.assign(params, { status: param.status })
            }
            if (param.interestStatus) {
                Object.assign(params, { interestStatus: param.interestStatus })
            }
            const { data } = await Axios.get(EmailCampaign.CAMPAIGN_RECIPIENT, {
                params: {
                    ...params
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param email
     * @returns status
     *
     */
    static async addInboxToCampaign(
        campaignId: string,
        email: string
    ): Promise<T.AddCampaignResponse> {
        try {
            const { data } = await Axios.post(
                EmailCampaign.CREATE_CAMPAIGN + "/" + campaignId + "/inboxes",
                {
                    inbox: email
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Update inbox to campaign
     * @param inboxId
     * @param Status
     * @returns status
     *
     */
    static async updateInboxStatus(
        inboxId: string,
        Status: string
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGN_INBOXES + "/" + inboxId + "/status",
                {
                    value: Status
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param sourceInbox
     * @param targetInboxes
     * @returns status
     *
     */
    static async replaceCampaignInbox(
        campaignId: string,
        sourceInbox: string,
        targetInboxes: string[]
    ): Promise<boolean> {
        try {
            const { status } = await Axios.post(
                EmailCampaign.CREATE_CAMPAIGN +
                    "/" +
                    campaignId +
                    "/recipients/inboxes",
                {
                    sourceInbox,
                    targetInboxes
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param id
     * @returns status
     *
     */
    static async removeInboxFromCampaign(id: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                EmailCampaign.CAMPAIGN_INBOXES + "/" + id
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     *
     * Add inbox to campaign
     * @returns TCampaignInbox
     *
     */
    static async updateCampaignInboxStatus(
        campaignId: string,
        inbox: string,
        status: string,
        id?: string
    ): Promise<TCampaignInbox> {
        try {
            const { data } = await Axios.post(
                EmailCampaign.CAMPAIGN_INBOXES + "/status",
                {
                    campaignId,
                    inbox,
                    status,
                    id
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param body
     * @returns status
     *
     */
    static async createRecipient(
        campaignId: string,
        body: Array<T.CreateRecipientBody>
    ): Promise<boolean> {
        try {
            const { data } = await Axios.put(EmailCampaign.CAMPAIGN_RECIPIENT, {
                campaignId: campaignId,
                value: body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param listNames string[]
     * @returns status
     *
     */
    static async postRecipient(
        campaignId: string,
        workspaceId: string,
        listIds: string[]
    ): Promise<boolean> {
        const applicationId = (window as any).CONFIG.VUE_APP_APP_ID
        const authToken = getEmailToken()
        try {
            const { data } = await workspaceAxios.post(
                `/workspaces/${workspaceId}${EmailCampaign.CREATE_RECIPIENT}`,
                {
                    campaignId,
                    listIds,
                    applicationId,
                    authToken
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param recipientId
     * @param body
     * @returns status
     *
     */
    static async deleteRecipient(recipientId: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                EmailCampaign.CAMPAIGN_RECIPIENT + "/" + recipientId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param body
     * @returns status
     *
     */
    static async createSchedule(
        campaignId: string,
        body: T.CreateSchedule
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/start`,
                {
                    ...body
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param campaignId
     * @param body
     * @returns status
     *
     */
    static async updateSchedule(
        campaignId: string,
        body: T.CreateSchedule
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/schedule`,
                {
                    ...body
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Export campaign recipients
     * @param campaignId
     * @returns status
     *
     */
    static async exportRecipient(campaignId: string): Promise<any> {
        try {
            const { data } = await Axios.post(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/recipients/csv`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Export campaign recipients
     * @param campaignId
     * @returns CampaignStaistics
     *
     */
    static async campaignStats(
        campaignId: string
    ): Promise<T.CampaignStaistics> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CREATE_CAMPAIGN + `/${campaignId}/stats`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Export campaign recipients
     * @param campaignId
     * @returns StageOptions
     *
     */
    static async getStageOptions(campaignId: string): Promise<T.StageOptions> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGNS +
                    `/${campaignId}` +
                    EmailCampaign.STAGES_COUNTER
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Export campaign recipients
     * @param campaignId
     * @returns StageOptions
     *
     */
    static async getCampaignStatusOptions(): Promise<T.StatusCounter> {
        try {
            const { data } = await Axios.get(EmailCampaign.STATUS_COUNTER)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Export campaign recipients
     * @param T.TestEmailType
     * @returns status
     *
     */
    static async sendTestEmail(body: T.TestEmailType): Promise<boolean> {
        try {
            const { data } = await Axios.post(EmailCampaign.TEST_EMAIL, {
                ...body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Generate email preview
     * @param messageId
     * @returns email
     *
     */
    static async previewEmail(
        messageId: string
    ): Promise<{ body: string; subject: string }> {
        try {
            const { data } = await Axios.post(EmailCampaign.PREVIEW_EMAIL, {
                messageId
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Generate email preview
     * @param campaignId
     * @param value
     * @returns email
     *
     */
    static async autoStopReply(
        campaignId: string,
        value: boolean
    ): Promise<{ status: boolean }> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGNS + `/${campaignId}/keepSequenceOnReply`,
                {
                    value
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get campaign dashboard stats
     * @param campaignId
     * @returns stats
     *
     */
    static async getCampaignStatistics(
        campaignId: string
    ): Promise<T.CampaignDashboardStats> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGNS + `/${campaignId}/dashboard`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Get campaign dashboard stats
     * @param sequenceId
     * @returns status
     *
     */
    static async toggleMessageActive(
        sequenceId: string,
        isActive: boolean
    ): Promise<boolean> {
        try {
            const { status } = await Axios.patch(
                EmailCampaign.CAMPAIGN_MESSAGES + `/${sequenceId}/isActive`,
                {
                    isActive
                }
            )
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Description getEmailStatsBySequence
     * @date 4/11/2023 - 8:03:18 PM
     * @static
     * @async
     * @param {string} sequence
     * @param {string} campaignId
     * @returns {Promise<boolean>}
     */
    static async getEmailStatsBySequence(
        sequence: string,
        campaignId: string
    ): Promise<T.SequenceStats[]> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGN_MESSAGES + `/sequence/${sequence}`,
                {
                    params: {
                        campaignId
                    }
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    /**
     * Update out of office delay
     * @param campaignId
     * @param value
     * @returns ICreateCampaignResponse
     *
     */
    static async updateOutofOfficeDelay(
        campaignId: string,
        value: number
    ): Promise<T.ICreateCampaignResponse> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGNS +
                    `/${campaignId}/repeatSequenceOnAutoReplyAfterDays`,
                {
                    value
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    /**
     * Gets list of  recipient messages
     * @param recipientId
     * @returns CampaignMessages
     *
     */
    static async getRecipientMessages(
        recipientId: string
    ): Promise<T.CampaignMessages> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGN_RECIPIENT_MESSEGES,
                {
                    params: {
                        recipientId,
                        orderBy: "-createdAt"
                    }
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    /**
     * Update recipient interest
     * @param recipientId
     * @param interestStatus
     * @returns ICreateCampaignResponse
     *
     */
    static async updateRecipientInterest(
        recipientId: string,
        interestStatus: T.TRecipientInterestStatus
    ): Promise<T.Recipient> {
        try {
            const { data } = await Axios.patch(
                EmailCampaign.CAMPAIGN_RECIPIENT +
                    `/${recipientId}/interestStatus`,
                {
                    interestStatus
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    /**
     * Gets a new recipients by id
     * @param recipientId
     * @returns CampaignMessages
     *
     */
    static async getRecipient(recipientId: string): Promise<T.Recipient> {
        try {
            const { data } = await Axios.get(
                EmailCampaign.CAMPAIGN_RECIPIENT + "/" + recipientId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
