import { GMAIL_INBOXES } from "@/services/gmail/inboxes"
import GeneralService from "@/services/General"
import store from "@/store"
import router from "@/router"
import { getBearerToken, getEmailToken } from "@/helpers/localStorage"
import { TSnakType } from "@/helpers/types"

export const isAuthenticated = async (): Promise<boolean> => {
    if (!getEmailToken()) {
        await GMAIL_INBOXES.retriveMeta().catch(console.log)
    }
    if (getBearerToken()) {
        store.dispatch(`setToken`)
        store.dispatch(`setUser`)
        if (store.state.token) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export const checkEmailAuth = async (to: any, next: any) => {
    if (to.query.code && typeof to.query.code === "string") {
        let redirectionUrl = ""
        if (to.query.state) {
            const data = JSON.parse(decodeURI(to.query.state))
            redirectionUrl = data.redirectionUrl
        }
        try {
            await GMAIL_INBOXES.exchangeToken(to.query.code, redirectionUrl)
        } catch (error) {
            next(redirectionUrl || "/account-settings/mailbox-settings")
            store.state.isRedirecting = false
            console.log(error)
            if (error instanceof Error) {
                store.commit("SET_SNAKBAR", {
                    show: true,
                    type: TSnakType.ERROR,
                    message: error.message
                })
            }
        } finally {
            store.state.isRedirecting = false
        }
    }
}
export const fetchUserDetails = async () => {
    if (store.state.BASE_USER) return true
    try {
        const data = await GeneralService.getUserDetails()
        store.commit("SET_USER_INFO", data)
    } catch (error) {
        if (error instanceof Error) {
            if (error.message === "Failed to verify authentication") {
                router.replace({
                    path: "/account/logout"
                })
            } else if (error.message === "Token verification failed") {
                router.replace({
                    path: "/account/logout"
                })
            }
        }
    }
}
