import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import Axios from "./plugins/axios"
import { Frontegg } from "@frontegg/vue"
import "./assets/main.css"
import "@mdi/font/css/materialdesignicons.css"
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import {
    internationalizationFn,
    internationalizationFillter,
    formatNumber
} from "./directives/intl"
import { dateFormat, dateTimeFormat } from "./directives/time"
import { onlyNumber, onlyString } from "./directives"
import "@/plugins/sentry"
import "./registerServiceWorker"
import "./plugins/nprogress"

Vue.config.productionTip = false
Vue.use(Toast, {
    containerClassName: "custom-toast-container"
})
Vue.prototype.axios = Axios
Vue.directive("intl", internationalizationFn)
Vue.directive("intlFilter", internationalizationFillter)
Vue.directive("dateformat", dateFormat)
Vue.directive("dateTimeFormat", dateTimeFormat)
Vue.directive("onlyNumber", onlyNumber)
Vue.directive("onlyString", onlyString)
Vue.directive("numberFormat", formatNumber)
Vue.use(
    Frontegg,
    {
        contextOptions: {
            baseUrl: (window as any).CONFIG.VUE_APP_FRONTEGG_BASE_URL,
            clientId: (window as any).CONFIG.VUE_APP_FRONTEGG_CLIENT_ID
        },
        authOptions: {
            keepSessionAlive: true // Uncomment this in order to maintain the session alive
        },
        hostedLoginBox: false,
        router,
        LottieAnimation
    },
    Toast
)
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    created() {
        this.$store.dispatch(`setToken`)
        this.$store.dispatch(`setUser`)
    }
}).$mount("#app")
