import { RouteConfig } from "vue-router"
import Error from "@/views/Error/index.vue"
import { mainRoutes } from "./mainRoutes"
import store from "@/store"
import { fetchUserDetails } from "@/middleware/Auth"
import Home from "@/views/index.vue"
export const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/dashboard",
        meta: {
            requireAuth: true
        },
        component: () => import("@/layouts/MainLayout.vue"),
        children: [...mainRoutes]
    },
    // Subscription routes
    {
        path: "/subscription",
        component: () => import("@/layouts/SubscriptionLayout.vue"),
        children: [
            {
                beforeEnter: async (to, from, next) => {
                    await fetchUserDetails()
                    if (store.state.BASE_USER?.isSubscribed) {
                        next("/")
                    } else {
                        next()
                    }
                },
                path: "/plans",
                name: "Plans",
                component: () => import("@/views/subscription.vue")
            }
        ]
    },
    {
        path: "/:catchAll(.*)*",
        name: "404",
        component: Error
    }
]
