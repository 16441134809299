<template>
    <v-app class="ma-2" v-if="fronteggLoaded && authState.user">
        <v-overlay
            :value="$store.state.isRedirecting"
            v-if="$store.state.isRedirecting"
        >
            <LottieAnimation
                path="lottie/simple-loader.json"
                :loop="true"
                :autoPlay="true"
                :speed="2"
                :width="150"
                :height="150"
            />
        </v-overlay>
        <router-view />
        <RefreshDialog
            title="An Update is available"
            subtitle="Please update to the latest version of the app to continue"
            btn-text="Update"
            :value="updateExists"
            @close="updateExists = false"
            @refresh="refreshApp"
        />
    </v-app>
    <v-app v-else>
        <div class="loader-wrapper" v-if="!fronteggLoaded">
            <div class="loader"></div>
        </div>
    </v-app>
</template>

<script lang="ts">
import { Route } from "vue-router"
import * as Sentry from "@sentry/vue"
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
import { INIT_SSE } from "@/helpers/InitSse"
import { GMAIL_INBOXES } from "@/services/gmail/inboxes"
import { mapGetters } from "vuex"
import { UserType } from "@/store/Types/Frontegg"
import RefreshDialog from "@/components/core/RefreshDialog.vue"

export default {
    name: "App",
    components: {
        LottieAnimation,
        RefreshDialog
    },
    data() {
        return {
            ...this.mapAuthState(),
            updateExists: false,
            refreshing: false,
            registration: {} as ServiceWorkerRegistration
        }
    },
    async updated(): Promise<void> {
        if (this.authState && this.authState.user) {
            const token = this.authState.user.accessToken
            localStorage.setItem("user", JSON.stringify(this.authState.user))
            localStorage.setItem("token", token)
            this.setCookie("token", token)
            this.$store.commit("SET_USER", this.authState.user)
            Sentry.setUser({
                email: this.authState.user.email,
                username: this.authState.user.name,
                id: this.authState.user.id,
                ip_address: this.authState.userIp
            })
        } else {
            localStorage.removeItem("user")
            localStorage.removeItem("EMAIL_TOKEN")
            localStorage.removeItem("token")
            this.deleteCookie("token")
            Sentry.setUser(null)
        }
    },
    created(): void {
        document.addEventListener("swUpdated", this.updateAvailable)
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            this.refreshing = true
            window.location.reload()
        })
    },
    methods: {
        getTenants() {
            this.$store.dispatch("getTenants")
        },
        updateLoginBackground() {
            const body = document.getElementById("body")
            const doc = document.getElementById("hero")
            if (!doc) return
            if (!body) return
            const urlPath = this.$route.fullPath
            const regexList = [/login/, /sign-up/]
            if (regexList.some((e) => e.test(urlPath))) {
                doc.style.display = "block"
                body.style.setProperty("--visibility", "none")
            } else {
                doc.style.display = "none"
                body.style.setProperty("--visibility", "block")
            }
        },
        updateAvailable(event: any): void {
            this.registration = event.detail
            this.updateExists = true
        },
        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: "SKIP_WAITING" })
        },
        setCookie(name: string, value: string) {
            const date = new Date()
            date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
            document.cookie = `${name}=${value}; expires=${date}; Path=/;`
        },
        deleteCookie(name: string) {
            document.cookie =
                name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        }
    },
    computed: {
        ...mapGetters(["getUser"])
    },
    watch: {
        getUser: {
            handler(newValue: UserType, oldValue: UserType) {
                if (oldValue.tenantId && newValue.tenantId) {
                    if (newValue.tenantId !== oldValue.tenantId) {
                        GMAIL_INBOXES.retriveMeta()
                            .catch(console.log)
                            .finally(() => {
                                const currentRoute = this.$route.path
                                const routePath = currentRoute
                                    .split("/")
                                    .filter((e) => e)
                                const redirectTo = routePath[0]
                                if (redirectTo && redirectTo !== "account") {
                                    window.location.href = `/${redirectTo}`
                                } else {
                                    window.location.reload()
                                }
                            })
                    }
                }
            }
        },
        $route: {
            handler(to: Route): void {
                const regexList = [/login/, /sign-up/, /account/]
                if (regexList.some((e) => e.test(to.fullPath))) {
                    document.title = `Prondo - Authentication`
                } else {
                    document.title = `Prondo - ${to.name}`
                }
            }
        },
        "$store.state.BASE_USER": {
            handler(v) {
                if (v) {
                    this.getTenants()
                    INIT_SSE()
                }
            }
        }
    }
}
</script>

<style scoped>
.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.loader {
    width: 8px;
    height: 40px;
    /* border-radius: 4px; */
    display: block;
    margin: 20px auto;
    position: relative;
    background: currentColor;
    color: #d0d0d0;
    box-sizing: border-box;
    animation: animloader 0.4s 0.4s linear infinite alternate;
    transform: scale(0.4);
}

.loader::after,
.loader::before {
    content: "";
    width: 8px;
    height: 40px;
    /* border-radius: 4px; */
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    box-sizing: border-box;
    animation: animloader 0.4s 0.5s linear infinite alternate;
}
.loader::before {
    left: -22px;
    animation-delay: 0s;
}

@media screen and (max-width: 599px) {
    .close-icon-wrapper {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

@keyframes animloader {
    0% {
        height: 48px;
        color: #d0d0d0;
    }
    100% {
        height: 4px;
        color: #bebebe;
    }
}
</style>
