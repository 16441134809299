import vue from "vue"
import { POSITION, TYPE } from "vue-toastification"
import ToastContent from "@/components/core/ToastContent.vue"

interface ToastOptions {
    id?: string
    title: string
    progress?: {
        processed: number
        succeeded: number
        total: number
    }
    type: keyof typeof TYPE
    snakbarType?: "static" | "dynamic"
    isCompleted?: boolean
    timeout?: number
    onClose?: () => void
}

const useToasttify = (options: ToastOptions) => {
    const Toast = vue.$toast
    const ToastId = vue.$toast(
        {
            component: ToastContent,
            props: {
                ...options
            }
        },
        {
            id: options?.id,
            position: POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            icon: false,
            type: options.type ? TYPE[options?.type] : TYPE.SUCCESS,
            timeout: options.timeout ? options.timeout : false,
            onClose: options.onClose
        }
    )
    return {
        ToastId,
        Toast
    }
}

export { useToasttify }
