import { WorkSpace } from "./ApiConstant"
import Axios from "@/plugins/axios"
import {
    TworkspaceList,
    TCreateWorkspace,
    TCheckWorkspace,
    IDatabaseShapeList,
    TWorkspaceShape,
    IDatabaseShape,
    EmailStatusStats,
    IDBexport,
    IWebsiteSuggestion,
    DatabasePayload
} from "./types"
import { getErrorMessage } from "@/helpers"
import store from "@/store"
import { TSnakType } from "@/helpers/types"
import { TMeta } from "../types/meta"

export class WorkSpaceService {
    static async getPersonIdentifiers(
        pageNum: number,
        limit: number,
        count: number,
        workspaceId: string,
        emailStatuses: string,
        listId: string
    ): Promise<{ ids: string[] }> {
        let url =
            WorkSpace.WORKSPACES +
            `/${workspaceId}/persons/search/ids?emailStatuses=${emailStatuses}`
        if (listId) {
            url += `&listId=${listId}`
        }

        const { data } = await Axios.post(url, {
            pageNum,
            limit,
            count,
            listId
        })
        return data
    }
    static async getWorkspaceList(
        searchText: string,
        meta?: Partial<TMeta>,
        pageNum = 0,
        isArchived?: boolean
    ): Promise<TworkspaceList> {
        const { data } = await Axios.get(WorkSpace.WORKSPACE_LIST, {
            params: {
                ...meta,
                isArchived,
                pageNum,
                searchText
            }
        })
        return data
    }
    static async getSampleFormat(): Promise<File> {
        const { data } = await Axios.get(WorkSpace.CSV_SAMPLE)
        return data
    }
    static async importPersonCsv(
        workspaceId: string,
        file: File
    ): Promise<boolean> {
        try {
            const formData = new FormData()
            formData.set("file", file)
            const { status } = await Axios({
                url:
                    WorkSpace.WORKSPACE_LIST +
                    `/${workspaceId}${WorkSpace.IMPORT_CSV}`,
                data: formData,
                method: "post",
                headers: {
                    "Content-Type": `application/form-data`
                }
            })
            return !!status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async exportPersonCsv(workspaceId: string): Promise<IDBexport> {
        try {
            const { data } = await Axios.post(
                WorkSpace.WORKSPACE_LIST +
                    `/${workspaceId}${WorkSpace.EXPORT_CSV}`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async exportListPerson(listId: string): Promise<IDBexport> {
        try {
            const { data } = await Axios.post(
                WorkSpace.LISTS + `/${listId}${WorkSpace.PERSONS}/csv`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getDatabases(
        url: string,
        meta?: Partial<TMeta>,
        emailStatuses?: string,
        lists?: string,
        searchText?: string
    ): Promise<IDatabaseShapeList> {
        try {
            const { data } = await Axios.get(url, {
                params: {
                    ...meta,
                    emailStatuses,
                    lists,
                    searchText
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getDatabasePaginated(
        payload: DatabasePayload
    ): Promise<IDatabaseShapeList> {
        try {
            const { pageNum, url, emailStatuses, lists, meta, searchText } =
                payload
            const { data } = await Axios.get(url, {
                params: {
                    searchText,
                    pageNum,
                    emailStatuses,
                    lists,
                    ...meta
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getDatabasesForImport(
        limit = 10,
        page = 0,
        emailStatuses?: string
    ): Promise<IDatabaseShapeList> {
        try {
            const { data } = await Axios.get(WorkSpace.WORKSPACE_LIST, {
                params: {
                    limit,
                    pageNum: page,
                    emailStatuses
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getWorkspaceListForImport(
        limit = 10,
        page = 0
    ): Promise<TworkspaceList> {
        try {
            const { data } = await Axios.get(WorkSpace.WORKSPACE_LIST, {
                params: {
                    limit,
                    pageNum: page
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async updateDatabase(
        id: string,
        body: {
            email?: string
            website?: string
        }
    ): Promise<boolean> {
        try {
            const { data } = await Axios.put(WorkSpace.PERSONS + `/${id}`, {
                ...body
            })
            return data
        } catch (err) {
            store.commit("SET_SNAKBAR", {
                show: true,
                message: getErrorMessage(err),
                type: TSnakType.ERROR
            })
            throw new Error(getErrorMessage(err))
        }
    }
    static async viewWorkspace(id: string): Promise<TWorkspaceShape> {
        const { data } = await Axios.get(WorkSpace.WORKSPACE_LIST + "/" + id)
        return data
    }

    static async createWorkspace(body: TCreateWorkspace): Promise<number> {
        try {
            const { status } = await Axios.post(WorkSpace.CREATE_WORKSPACE, {
                ...body
            })
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async updateWorkspace(
        body: TCreateWorkspace,
        id: string
    ): Promise<number> {
        try {
            const { status } = await Axios.put(
                WorkSpace.CREATE_WORKSPACE + "/" + id,
                {
                    ...body
                }
            )
            return status
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }
    static async deleteWorkspace(id: string): Promise<string> {
        try {
            const response = await Axios.delete(
                WorkSpace.CREATE_WORKSPACE + "/" + id
            )
            return response.statusText
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }
    static async archiveWorkspace(id: string): Promise<string> {
        try {
            const response = await Axios.patch(
                WorkSpace.CREATE_WORKSPACE + "/" + id + "/archive"
            )
            return response.statusText
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }
    static async restoreWorkspace(id: string): Promise<string> {
        try {
            const response = await Axios.patch(
                WorkSpace.CREATE_WORKSPACE + "/" + id + "/restore"
            )
            return response.statusText
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }
    static async checkWorkspacExist(
        body: TCheckWorkspace
    ): Promise<{ result: boolean }> {
        const { data } = await Axios.post(WorkSpace.CHECK_WORKSPACE_NAME, {
            ...body
        })
        return data
    }
    static async createPerson(
        body: Partial<IDatabaseShape>
    ): Promise<{ result: boolean }> {
        try {
            const { data } = await Axios.post(WorkSpace.PERSONS, {
                ...body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }

    static async getPerson(id: string): Promise<IDatabaseShape> {
        const { data } = await Axios.get(WorkSpace.PERSONS + "/" + id)
        return data
    }

    static async deletePerson(id: string): Promise<string> {
        try {
            const response = await Axios.delete(WorkSpace.PERSONS + "/" + id)
            return response.statusText
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }
    static async deletePersons(
        workspaceId: string,
        ids: string[] = []
    ): Promise<string> {
        try {
            const { data } = await Axios.post(
                WorkSpace.CREATE_WORKSPACE +
                    `/${workspaceId}` +
                    WorkSpace.PERSONS +
                    "/delete",
                { ids }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async verifyEmail(id: string): Promise<boolean> {
        const response = await Axios.post(
            WorkSpace.PERSONS + "/" + id + "/email/verify"
        )
        return !response.status
    }
    static async findEmail(id: string): Promise<boolean> {
        const response = await Axios.post(
            WorkSpace.PERSONS + "/" + id + "/email/construct"
        )
        return !response.status
    }
    static async verifyEmails(
        workspaceId: string,
        ids: string[] = [],
        listId?: string
    ): Promise<boolean> {
        let params = {}
        if (listId) {
            params = { listId }
        }
        try {
            const response = await Axios.post(
                WorkSpace.CREATE_WORKSPACE +
                    `/${workspaceId}` +
                    WorkSpace.PERSONS +
                    "/email/verify",
                {
                    ids
                },
                {
                    params
                }
            )
            return !response.status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async findEmails(
        workspaceId: string,
        ids: string[] = [],
        service?: string,
        isMissingOnly = true,
        enrichViaClearbit = false,
        listId?: string
    ): Promise<{
        service: string
        recordsCount: number
        dropcontactCredits: number
        message?: string
    }> {
        try {
            let params: {
                service?: string
                enrichViaClearbit: boolean
                missingOnly?: boolean
                listId?: string
            } = {
                service,
                enrichViaClearbit
            }
            if (!ids.length) {
                params = { ...params, missingOnly: isMissingOnly }
            }
            if (listId) {
                params = { ...params, listId }
            }
            const { data } = await Axios.post(
                WorkSpace.CREATE_WORKSPACE +
                    `/${workspaceId}` +
                    WorkSpace.PERSONS +
                    "/email/find",
                {
                    ids
                },
                {
                    params
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getEmailStatusStats(
        workspaceId: string
    ): Promise<EmailStatusStats> {
        try {
            const { data } = await Axios.get(
                WorkSpace.WORKSPACES +
                    `/${workspaceId}/persons/emailStatusesCounter`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getEmailStatusStatsForList(
        listId: string
    ): Promise<EmailStatusStats> {
        try {
            const { data } = await Axios.get(
                `lists/${listId}/persons/emailStatusesCounter`
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getWebsiteSuggestion(
        query: string
    ): Promise<IWebsiteSuggestion[]> {
        try {
            const { data } = await Axios.get(
                WorkSpace.CONNECTED_SERVICE_COMPANIES,
                {
                    params: {
                        query
                    }
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
