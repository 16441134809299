<script setup lang="ts">
import HelpSvg from "@/assets/prondo/help.svg"
import LineSvg from "@/assets/prondo/line.svg"
</script>
<template>
    <div class="settings">
        <v-container>
            <div style="height: 150px"></div>
            <div class="d-flex flex-column align-center">
                <div class="d-flex align-center" style="gap: 2px">
                    <HelpSvg width="24px" height="24px" />
                    <div class="error-404">Error - 404</div>
                </div>
                <div class="nf-wrapper">
                    <h1 class="surname">Page Not found</h1>
                    <LineSvg class="line" width="100px" height="24px" />
                </div>
                <p class="d-flex align-center">
                    <span class="text-center">
                        The page you were looking for doesn't exist<br />
                        You may have mistyped the address or the page may have
                        moved
                    </span>
                </p>
                <v-btn
                    depressed
                    class="white--text"
                    rounded
                    color="outlined-orange"
                    to="/"
                    >back to home</v-btn
                >
            </div>
        </v-container>
    </div>
</template>

<style>
.nf-wrapper {
    position: relative;
}
.line {
    width: 100px;
    transform: translateY(-10px) translateX(190px);
}
</style>
