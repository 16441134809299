import { GetterTree } from "vuex"
import { BaseState } from "../Types/BaseState"
import { getEmailToken } from "@/helpers/localStorage"
const getters: GetterTree<BaseState, BaseState> = {
    getToken(state) {
        return state.token
    },
    getEmailToken() {
        return getEmailToken()
    },
    getUser(state) {
        return state.user
    },
    getAvailableAccount(state) {
        return state.Tenants?.filter((e) => e.tenantId !== state.BASE_USER?.id)
    },
    getTotalWorkspace(state) {
        return state.workspaceList.meta.total
    },
    getWorkspaceList(state) {
        return state.workspaceList
    },
    isWorkspaceFetching(state) {
        return state.isLoading
    },
    getTotalDatabase(state) {
        return state.workspaceList.meta.total
    }
}

export default getters
