import Axios from "@/plugins/axios"
import { getErrorMessage } from "@/helpers"
import { List } from "./ApiConstants"
import {
    CreateListResponse,
    IAddList,
    ICreateList,
    IList,
    ILists
} from "./types"
import { TMeta } from "../types/meta"
export class ListService {
    static async createList(body: ICreateList): Promise<CreateListResponse> {
        try {
            const { data } = await Axios.post(List.CREATE_LIST, {
                ...body
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async addToList(body: IAddList, id: string): Promise<number> {
        try {
            const { status } = await Axios.put(
                List.CREATE_LIST + "/" + id + "/persons",
                {
                    ...body
                }
            )
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async addPersonToList(
        personIds: string[],
        listIds: string[]
    ): Promise<number> {
        try {
            const { status } = await Axios.put(List.ATTACH_PERSON_TO_LIST, {
                listIds,
                personIds
            })
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async removeFromList(
        personIds: string[],
        listIds: string[]
    ): Promise<number> {
        try {
            const { status } = await Axios.put(List.CREATE_LIST + "/detach", {
                listIds: listIds,
                personIds
            })
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async removePersonFromList(
        personIds: string[],
        listIds: string[]
    ): Promise<number> {
        try {
            const { status } = await Axios.put(List.DETACH_PERSON_TO_LIST, {
                listIds,
                personIds
            })
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async editList(listId: string, name: string): Promise<number> {
        try {
            const { status } = await Axios.put(List.LISTS + `/${listId}`, {
                name
            })
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async archiveList(listId: string): Promise<number> {
        try {
            const { status } = await Axios.patch(
                List.LISTS + `/${listId}/archive`
            )
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async restoreList(listId: string): Promise<number> {
        try {
            const { status } = await Axios.patch(
                List.LISTS + `/${listId}/restore`
            )
            return status
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getLists(
        workspaceId: string,
        limit = 10,
        page = 0,
        searchText?: string
    ): Promise<ILists> {
        const { data } = await Axios.get(
            List.WORKSPACES + workspaceId + List.LISTS,
            {
                params: {
                    limit: limit,
                    pageNum: page,
                    searchText
                }
            }
        )
        return data
    }
    static async getListsForSelect(
        workspaceId: string,
        meta: Partial<TMeta>
    ): Promise<ILists> {
        const { data } = await Axios.get(
            List.GET_LIST + workspaceId + List.CREATE_LIST,
            {
                params: {
                    ...meta
                }
            }
        )
        return data
    }
    static async getListById(id: string): Promise<IList> {
        const { data } = await Axios.get(List.CREATE_LIST + "/" + id)
        return data
    }
    static async getListsByPersons(
        ids: string[],
        workspaceId: string
    ): Promise<{ result: IList[] }> {
        const { data } = await Axios.post(
            `/workspaces/${workspaceId}${List.SEARCH_LIST}`,
            {
                ids
            }
        )
        return data
    }
}
