import env from "@/env"
import router from "@/router"
import { BrowserTracing } from "@sentry/tracing"
import * as Sentry from "@sentry/vue"
import Vue from "vue"

Sentry.init({
    Vue,
    environment: env.NODE_ENV,
    enabled: env.NODE_ENV !== "development",
    dsn: `https://a57cc3ea463146b3a559b21ac001d34c@o4504317618487296.ingest.sentry.io/4504317620912128`,
    release: "fe-release",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "app.prondo.co", "uat.prondo.co"]
        })
    ],
    logErrors: env.NODE_ENV !== "production" ? true : false,
    // debug: env.NODE_ENV !== "production",
    tracesSampleRate: env.NODE_ENV !== "production" ? 1 : 0.25,
    tracingOptions: {
        trackComponents: true
    },
    attachProps: true,
    attachStacktrace: true
})
