import { ref } from "vue"
import { EventSourcePolyfill } from "event-source-polyfill"
import { getBearerToken } from "@/helpers/localStorage"
import { StreamEvent, StreamEventType } from "@prondo/stream-events"

export interface SseServiceOptions {
    sseUrl: string
}

interface SseMessage {
    data: string
}

interface SSESubscription {
    unsubscribe: () => void
    handle: (data: StreamEvent) => void
}

export type SubscriptionsMapEntry = Record<symbol, SSESubscription>

const useEventSource = (options: SseServiceOptions) => {
    const eventSourcePolyfill = ref<EventSourcePolyfill | undefined>()
    const subscriptionsMap = new Map<
        StreamEventType,
        Map<SSESubscription, SSESubscription>
    >()

    const handleMessage = (message: unknown) => {
        const { data } = message as SseMessage
        const event = JSON.parse(data) as StreamEvent
        const subsMap =
            subscriptionsMap.get(event.type) ||
            new Map<SSESubscription, SSESubscription>()

        Array.from(subsMap.values()).forEach((subscription) =>
            subscription.handle(event)
        )
    }

    const handleError = (error: unknown) => {
        console.log({ error })
    }
    const connect = (token?: string) => {
        const headers = {
            Authorization: `Bearer ${token || getBearerToken()}`
        }
        if (!eventSourcePolyfill.value) {
            eventSourcePolyfill.value = new EventSourcePolyfill(
                options.sseUrl,
                {
                    headers
                }
            )
            eventSourcePolyfill.value.addEventListener("error", (error) => {
                handleError(error)
            })

            eventSourcePolyfill.value.addEventListener("message", (message) => {
                handleMessage(message)
            })
        }
    }

    const setAuthToken = (newToken: string) => {
        connect(newToken)
    }

    const disconnect = () => {
        eventSourcePolyfill.value?.close()
        delete eventSourcePolyfill.value
    }

    const subscribe = (
        type: StreamEventType,
        handler: (data: StreamEvent) => void
    ) => {
        const subscription = {} as SSESubscription
        subscription.handle = handler
        subscription.unsubscribe = () => {
            const subsSection = subscriptionsMap.get(type)
            subsSection?.delete(subscription)
            console.log("SUBSCRIPTION DELETED")
        }

        const subsSectionByType = subscriptionsMap.get(type) || new Map()
        subsSectionByType.set(subscription, subscription)
        subscriptionsMap.set(type, subsSectionByType)
        console.log("SUBSCRIPTION CREATED")
        return subscription
    }

    return {
        connect,
        setAuthToken,
        disconnect,
        subscribe
    }
}

export { useEventSource }
