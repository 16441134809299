import { cleanEnv, num, str, url } from "envalid"

const env = cleanEnv(process.env, {
    /**
     * The base url that's allowed in Frontegg
     */
    VUE_APP_FRONTEGG_BASE_URL: url(),
    /**
     * The base url for the notification service
     */
    VUE_APP_NOTIFICATION_BASE_URL: url(),
    VUE_APP_EMAIL_SERVICE_BASE_URL: url(),
    VUE_APP_HTTP_REQUEST_TIMEOUT: num({ default: 0 }),
    /**
     * The Client ID that matches to a Frontegg Environment
     */
    VUE_APP_FRONTEGG_CLIENT_ID: str(),
    /**
     * This is to differentiate Email service api from different app environment
     * to use app platform specific features.
     */
    VUE_APP_APP_ID: str(),
    /**
     * URL of the backend, this is important
     */
    VUE_APP_BACKEND_API_URL: url(),
    /**
     * Different possible environments for the app
     */
    NODE_ENV: str({
        choices: ["beta", "production", "development", "staging"]
    }),
    /**
     * URL where the app is hosted
     */
    VUE_APP_BASE_URL: url({
        choices: [
            "http://localhost:8000",
            "https://app.prondo.co",
            "https://leopb-dev.firebaseapp.com",
            "https://uat.prondo.co",
            "https://leopb-uat.web.app",
            "https://dev.prondo.co",
            "https://beta.prondo.co"
        ]
    }),
    /**
     * The id of the pricing table from stripe,
     */
    VUE_APP_STRIPE_PRICING_TABLE_ID: str(),
    VUE_APP_STRIPE_PUBLIC_KEY: str()
})

export default env
