var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.ProndoModal,{attrs:{"max-width":"600px","value":_setup.isOpen,"show-close":""},on:{"close":_setup.goBack}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _setup.createWorkspace.apply(null, arguments)}},model:{value:(_setup.isValid),callback:function ($$v) {_setup.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_setup.prop.isEdit ? "Update Workspace" : "Create Workspace"))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_setup.getErrorMessage(),"loading":_setup.store.state.isWorkspaceChecking,"label":"Workspace Name*","rules":[
                                            ..._setup.requiredRule('Workspace name'),
                                            _setup.maximumCharacter(
                                                40,
                                                'Workspace Name'
                                            )
                                        ],"required":""},on:{"input":_setup.checkWorkspaceExist},model:{value:(_setup.workspaceName),callback:function ($$v) {_setup.workspaceName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"workspaceName"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-start pa-5"},[_c('v-btn',{staticClass:"white--text outlined-orange flex-grow-1",attrs:{"max-width":"222px","height":"40px","depressed":"","rounded":"","disabled":!_setup.isValid,"loading":_setup.isLoading},on:{"click":_setup.createWorkspace}},[(!_setup.prop.isEdit)?_c(_setup.plusIcon,{attrs:{"width":"25px","height":"25px"}}):_vm._e(),_vm._v(" "+_vm._s(_setup.prop.isEdit ? "Update workspace" : " Create Workspace")+" ")],1),_c('v-btn',{staticClass:"cancel-btn",attrs:{"depressed":""},on:{"click":_setup.goBack}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }