import CreateWorkspace from "@/views/workspace/actions/createWorkspace.vue"
import UpdateWorkspace from "@/views/workspace/actions/updateWorkspace.vue"
import Survey from "@/views/Survey.vue"
import { Route, RouteConfig } from "vue-router"
export const mainRoutes: Array<RouteConfig> = [
    {
        path: "/survey",
        name: "Survey",
        meta: {
            requireAuth: true
        },
        component: Survey
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
            ),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/onboarding",
        name: "Getting Started",
        component: () =>
            import(
                /* webpackChunkName: "onboarding" */ "@/views/onboarding/index.vue"
            ),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/workspaces",
        name: "Workspaces",
        meta: {
            requireAuth: true,
            breadCrumb() {
                return [
                    {
                        text: "Workspaces",
                        to: { path: "/workspaces" }
                    }
                ]
            }
        },
        component: () =>
            import(
                /* webpackChunkName: "workspaces" */ "@/views/workspace/workspaces.vue"
            ),

        children: [
            {
                name: "Create workspace",
                path: "create-workspace",
                component: CreateWorkspace
            },
            {
                name: "Update Workspace",
                path: "update-workspace/:id",
                component: UpdateWorkspace
            }
        ]
    },
    {
        path: "/workspaces/:workspace_id",
        name: "Workspace",
        redirect: "/workspaces/:workspace_id/databases",
        component: () =>
            import(
                /* webpackChunkName: "view" */ "@/views/workspace/workpsace.vue"
            ),
        meta: {
            requireAuth: true,
            breadCrumb() {
                // const workspace_id = route.params.workspace_id
                return [
                    {
                        text: "Workspaces",
                        to: { path: "/workspaces" }
                    },
                    {
                        text: "Details",
                        disabled: true
                    }
                ]
            }
        },
        children: [
            {
                path: "databases",
                name: "Database",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Workspaces",
                                to: { path: "/workspaces" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            },
                            {
                                text: "Database",
                                disabled: true
                            }
                        ]
                    }
                },
                children: [
                    {
                        path: "create-task",
                        name: "Create Task",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Workspaces",
                                        to: {
                                            path: "/workspaces"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    },
                                    {
                                        text: "Database",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "create-task" */ "@/views/workspace/tasks/createTask.vue"
                            )
                    },
                    {
                        path: "create-person",
                        name: "Create Person",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Workspaces",
                                        to: {
                                            path: "/workspaces"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    },
                                    {
                                        text: "Database",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "create-person" */ "@/views/workspace/actions/createDatabase.vue"
                            )
                    },
                    {
                        path: "update-person/:personId",
                        name: "Update Person",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Workspaces",
                                        to: {
                                            path: "/workspaces"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    },
                                    {
                                        text: "Database",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "update-person" */ "@/views/workspace/actions/updateDatabase.vue"
                            )
                    }
                ],
                component: () =>
                    import(
                        /* webpackChunkName: "databases" */ "@/views/workspace/databases/databases.vue"
                    )
            },
            {
                path: "lists",
                name: "Lists",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Workspaces",
                                to: { path: "/workspaces" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            },
                            {
                                text: "Lists",
                                disabled: true
                            }
                        ]
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "lists" */ "@/views/workspace/lists/lists.vue"
                    )
            },
            {
                path: "tasks",
                name: "Tasks",
                component: () =>
                    import(
                        /* webpackChunkName: "task" */ "@/views/workspace/tasks/tasks.vue"
                    ),
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Workspaces",
                                to: { path: "/workspaces" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            },
                            {
                                text: "Tasks",
                                disabled: true
                            }
                        ]
                    }
                }
            }
        ]
    },
    {
        path: "/workspaces/:workspace_id/list/:list_id",
        name: "List",
        component: () =>
            import(
                /* webpackChunkName: "list-view" */ "@/views/workspace/lists/listView.vue"
            ),
        meta: {
            requireAuth: true,
            breadCrumb(route: Route) {
                const workspace_id = route.params.workspace_id
                return [
                    {
                        text: "Workspaces",
                        to: { path: "/workspaces" }
                    },
                    {
                        text: "Details",
                        to: {
                            path: `/workspaces/${workspace_id}/databases`
                        }
                    },
                    {
                        text: "Lists",
                        to: {
                            path: `/workspaces/${workspace_id}/lists`
                        }
                    },
                    {
                        text: "List Details",
                        disabled: true
                    }
                ]
            }
        }
    },
    {
        path: "/workspaces/:workspace_id/update-task/:task_id",
        name: "Update-task",
        component: () =>
            import(
                /* webpackChunkName: "new-task" */ "@/views/workspace/tasks/update-task.vue"
            ),
        meta: {
            requireAuth: true,
            breadCrumb(route: Route) {
                const workspace_id = route.params.workspace_id
                // const task_id = route.params.task_id
                return [
                    {
                        text: "Workspaces",
                        to: { path: "/workspaces" }
                    },
                    {
                        text: "Details",
                        to: {
                            path: `/workspaces/${workspace_id}`
                        }
                    },
                    {
                        text: "Update Task",
                        disabled: true
                    }
                ]
            }
        }
    },
    {
        path: "/applications",
        name: "Applications",
        meta: {
            requireAuth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "applications" */ "@/views/applications/index.vue"
            )
    },
    {
        path: "/campaigns",
        name: "Email Campaigns",
        meta: {
            requireAuth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "campaigns" */ "@/views/campaigns/campaigns.vue"
            ),
        children: [
            {
                path: "create",
                name: "Create",
                component: () =>
                    import(
                        /* webpackChunkName: "create-campaign" */ "@/views/campaigns/createCampaign.vue"
                    )
            }
        ]
    },
    {
        path: "/campaigns/:id",
        name: "Campaign",
        meta: {
            requireAuth: true,
            breadCrumb() {
                return [
                    {
                        text: "Campaigns",
                        to: { path: "/campaigns" }
                    },
                    {
                        text: "Details",
                        disabled: true
                    }
                ]
            }
        },
        component: () =>
            import(
                /* webpackChunkName: "campaign" */ "@/views/campaigns/view/campaign.vue"
            ),
        children: [
            {
                path: "dashboard",
                name: "Campaign Dashboard",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/campaigns/view/dashboard.vue"
                    )
            },
            {
                path: "recipients",
                name: "Recipients",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "recipients" */ "@/views/campaigns/view/recipients.vue"
                    ),
                children: [
                    {
                        path: "import",
                        name: "Import Recipient",
                        component: () =>
                            import(
                                /* webpackChunkName: "import-recipients" */ "@/views/campaigns/view/recipient/importRecipient.vue"
                            )
                    },
                    {
                        path: "create",
                        name: "Create Recipient",
                        component: () =>
                            import(
                                /* webpackChunkName: "create-recipients" */ "@/views/campaigns/view/recipient/createRecipient.vue"
                            )
                    },
                    {
                        path: "emails/:recipientId",
                        name: "View Emails",
                        component: () =>
                            import(
                                /* webpackChunkName: "view-emails" */ "@/views/campaigns/view/recipient/EmailReplies.vue"
                            )
                    }
                ]
            },
            {
                path: "sequence",
                name: "Sequence",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "sequence" */ "@/views/campaigns/view/sequence.vue"
                    ),
                children: [
                    {
                        path: ":sequence/abtesting-stats",
                        name: "A/B testing stats",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "ab-testing-stats" */ "@/views/campaigns/view/sequence/ABTestingStats.vue"
                            )
                    },
                    {
                        path: "create-sequence",
                        name: "Create Sequence",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "create-sq" */ "@/views/campaigns/view/sequence/createSequence.vue"
                            )
                    },
                    {
                        path: ":sequenceId/copy",
                        name: "Copy Sequence",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "copy-sq" */ "@/views/campaigns/view/sequence/CopySequence.vue"
                            )
                    },
                    {
                        path: ":sequenceId/update",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        name: "Update Sequence",
                        component: () =>
                            import(
                                /* webpackChunkName: "update" */ "@/views/campaigns/view/sequence/updateSequence.vue"
                            )
                    },
                    {
                        path: ":sequenceId/preview",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        name: "Message Preview",
                        component: () =>
                            import(
                                /* webpackChunkName: "preview" */ "@/views/campaigns/view/sequence/messagePreview.vue"
                            )
                    },
                    {
                        path: ":sequenceId/delay",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        name: "Update Delay",
                        component: () =>
                            import(
                                /* webpackChunkName: "delay" */ "@/views/campaigns/view/sequence/updateDelay.vue"
                            )
                    }
                ]
            },
            {
                path: "inboxes",
                name: "Inboxes",
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "inboxes" */ "@/views/campaigns/view/inboxes.vue"
                    )
            },
            {
                path: "schedule",
                name: "Schedule",
                component: () =>
                    import(
                        /* webpackChunkName: "schedule" */ "@/views/campaigns/view/schedule.vue"
                    ),
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                },
                children: [
                    {
                        name: "Create Schedule",
                        path: "create",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "create-schedule" */ "@/views/campaigns/view/schedule/createSchedule.vue"
                            )
                    },
                    {
                        name: "Update Schedule",
                        path: "update",
                        meta: {
                            requireAuth: true,
                            breadCrumb() {
                                return [
                                    {
                                        text: "Campaigns",
                                        to: {
                                            path: "/campaigns"
                                        }
                                    },
                                    {
                                        text: "Details",
                                        disabled: true
                                    }
                                ]
                            }
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "update-schedule" */ "@/views/campaigns/view/schedule/updateSchedule.vue"
                            )
                    }
                ]
            },
            {
                path: "settings",
                name: "Settings",
                component: () =>
                    import(
                        /* webpackChunkName: "campaign-settings" */ "@/views/campaigns/view/settings.vue"
                    ),
                meta: {
                    requireAuth: true,
                    breadCrumb() {
                        return [
                            {
                                text: "Campaigns",
                                to: { path: "/campaigns" }
                            },
                            {
                                text: "Details",
                                disabled: true
                            }
                        ]
                    }
                }
            }
        ]
    },
    {
        path: "/campaigns/:id/inbox/:inboxId",
        name: "Overview",
        meta: {
            requireAuth: true,
            breadCrumb(route: Route) {
                const campaignId = route.params.id
                return [
                    {
                        text: "Campaigns",
                        to: { path: "/campaigns" }
                    },
                    {
                        text: "Mailboxes",
                        to: {
                            path: "/campaigns/" + campaignId + "/inboxes"
                        }
                    },
                    {
                        text: "details",
                        disabled: true
                    }
                ]
            }
        },
        component: () =>
            import(
                /* webpackChunkName: "overview-inbox" */ "@/views/campaigns/view/inboxes/overview.vue"
            )
    },
    {
        path: "/account-settings",
        component: () =>
            import(
                /* webpackChunkName: "account-settings" */ "@/views/accounts/index.vue"
            ),
        redirect: "/account-settings/general-settings",
        children: [
            {
                path: "general-settings",
                name: "General Settings",

                component: () =>
                    import(
                        /* webpackChunkName: "general-settings" */ "@/views/accounts/generalSettings.vue"
                    ),
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "mailbox-settings",
                name: "Mailbox Settings",
                meta: {
                    requireAuth: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "mailbox-settings" */ "@/views/accounts/MailboxSettings.vue"
                    ),
                children: [
                    {
                        path: "custom-domain/:id",
                        name: "Update Custom Domain",
                        component: () =>
                            import(
                                /* webpackChunkName: "gmail-inboxes" */ "@/views/accounts/actions/customDomain.vue"
                            )
                    },
                    {
                        path: "daily-limit/:id",
                        name: "Update Limit",
                        component: () =>
                            import(
                                /* webpackChunkName: "daily-limit" */ "@/views/accounts/actions/DailyLimit.vue"
                            )
                    },
                    {
                        path: "signature/:id",
                        name: "Add Signature",
                        component: () =>
                            import(
                                /* webpackChunkName: "inbox-signature" */ "@/views/accounts/actions/InboxSignature.vue"
                            )
                    }
                ]
            },
            {
                path: "salesnav",
                name: "Sales Nav",
                meta: {
                    requireAuth: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "sales-nav" */ "@/views/accounts/salesnav.vue"
                    )
            },
            {
                path: "linkedin-settings",
                name: "Linkedin settings",
                meta: {
                    requireAuth: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "linkedin-nav" */ "@/views/accounts/linkedInSettings.vue"
                    )
            },
            {
                path: "attribute-settings",
                name: "Attribute Settings",
                meta: {
                    requireAuth: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "attributes" */ "@/views/accounts/attributes.vue"
                    ),
                children: [
                    {
                        path: "create",
                        name: "Create Attribute",
                        component: () =>
                            import(
                                /* webpackChunkName: "create-attritube" */ "@/views/accounts/attribute/createAttribute.vue"
                            )
                    },
                    {
                        path: "update",
                        name: "Update Attribute",
                        component: () =>
                            import(
                                /* webpackChunkName: "update-attritube" */ "@/views/accounts/attribute/updateAttribute.vue"
                            )
                    }
                ]
            }
        ]
    }
]
