import { DirectiveOptions } from "vue"
import { numberFormatter } from "@/helpers"
export const internationalizationFn: DirectiveOptions = {
    bind: (el, binding) => {
        el.innerText = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(binding.value || 0)
    },
    update: (el, binding) => {
        el.innerText = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(binding.value || 0)
    }
}
export const internationalizationFillter: DirectiveOptions = {
    bind: (el, binding) => {
        const val = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(binding.value || 0)
        el.innerText = `All ${val}`
    },
    update: (el, binding) => {
        const val = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(binding.value || 0)
        el.innerText = `All ${val}`
    }
}
export const formatNumber: DirectiveOptions = {
    bind: (el, binding) => {
        const { value, step } = binding.value
        const val = numberFormatter(value, step)
        el.innerText = val
    }
}
