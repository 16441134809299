export class WorkSpace {
    static readonly WORKSPACE_LIST = "/workspaces"
    static readonly CREATE_WORKSPACE = "/workspaces"
    static readonly WORKSPACES = "/workspaces"
    static readonly CHECK_WORKSPACE_NAME = "/workspaces/name/check"
    static readonly PERSONS = "/persons"
    static readonly LISTS = "/lists"
    static readonly CSV_SAMPLE = "/persons/import/csv"
    static readonly IMPORT_CSV = "/persons/import/csv"
    static readonly EXPORT_CSV = "/persons/csv"
    static readonly CONNECTED_SERVICE_COMPANIES =
        "/connectedServices/companies/suggest"
}
