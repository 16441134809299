export const DATABASE_HEADER_KEY = "DB_HEADERS"

export const getBearerToken = (): string | null => {
    return localStorage.getItem("token")
}
export const getEmailToken = (): string | null => {
    return localStorage.getItem("EMAIL_TOKEN")
}

export const GET_DB_HEADERS = (): string | null => {
    return localStorage.getItem(DATABASE_HEADER_KEY)
}
