import Vue from "vue"
import Vuetify from "vuetify"
import { VuetifyThemeVariant } from "vuetify/types/services/theme"
import "vuetify/dist/vuetify.min.css"

Vue.use(Vuetify)
const theme: Partial<VuetifyThemeVariant> = {
    primary: "#3043EE",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    accentBlue: "#3043EE",
    accentOrange: "#FC7F3B",
    accentOrangeDark: "#FF8039",
    neutralBlue: "#313451",
    neutralBlueLighten4: "#F3F4FE",
    systemRed: "#FF1414",
    systemYellow: "#DB9E03",
    systemGreen: "#32BD62",
    systemGreen2: "#0A943A"
}
export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: theme
        }
    }
})
