import { ActionTree } from "vuex"
import { BaseState } from "./BaseState"
import { BaseState as RootState } from "@/store/Types/BaseState"
import EmailService from "@/services/EmailCampaign"
import { GMAIL_INBOXES } from "@/services/gmail/inboxes"
import EmailCampaigns from "@/services/EmailCampaign"
import {
    ICreateCampaignResponse,
    RecipientParams
} from "@/services/EmailCampaign/types"
import { InboxParams } from "@/services/gmail/types"

const actions: ActionTree<BaseState, RootState> = {
    getStageOptions: async ({ commit }, { campaignId }) => {
        try {
            commit("SET_STAGE_OPTION_LOADING", true)
            const data = await EmailService.getStageOptions(campaignId)
            commit("SET_STAGE_OPTIONS", data)
        } catch (error) {
            console.log(error)
        } finally {
            commit("SET_STAGE_OPTION_LOADING", false)
        }
    },
    getInboxOptions: async ({ commit }, { campaignId }) => {
        try {
            commit("SET_INBOX_OPTION_LOADING", true)
            const data = await GMAIL_INBOXES.getInboxOptions(
                { limit: 50 },
                campaignId
            )
            const inboxes = data.data
                .filter((f) => f.campaignInbox)
                .map((g) => {
                    return {
                        text: g.email,
                        value: g.email
                    }
                })
            if (inboxes.length) {
                inboxes.unshift({
                    value: "all",
                    text: "All"
                })
            }
            commit("SET_INBOX_OPTIONS", inboxes)
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_INBOX_OPTION_LOADING", false)
        }
    },
    getCampaignMessages: async ({ commit }, { campaignId, isGroupped }) => {
        try {
            commit("SET_CAMPAIGN_MESSAGES_LOADING", true)
            const data = await EmailCampaigns.getCampaignMessages(
                campaignId,
                isGroupped,
                {
                    limit: 50
                }
            )
            if (isGroupped) {
                commit("SET_SEQUENCE_MESSAGES", data)
            } else {
                commit("SET_CAMPAIGN_MESSAGES", data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_CAMPAIGN_MESSAGES_LOADING", false)
        }
    },
    getRecipients: async ({ commit }, payload: RecipientParams) => {
        try {
            commit("SET_RECIPIENT_LOADING", true)
            const data = await EmailCampaigns.getRecipients(payload)
            commit("SET_RECIPIENTS", data)
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_RECIPIENT_LOADING", false)
        }
    },
    getInboxes: async (
        { commit },
        { pageNum, meta, campaignId }: InboxParams
    ) => {
        try {
            commit("SET_INBOX_LOADING", true)
            const data = await GMAIL_INBOXES.getInboxes({
                meta,
                pageNum,
                campaignId
            })
            commit("SET_INBOXES", data)
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_INBOX_LOADING", false)
        }
    },
    getCampaign: async (
        { commit, dispatch },
        { campaignId }: { campaignId: string }
    ) => {
        try {
            commit("SET_CAMPAIGN_LOADING", true)
            const data = await EmailService.getCampaignById(campaignId)
            dispatch("setChartData", data)
            commit("SET_CAMPAIGN", data)
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_CAMPAIGN_LOADING", false)
        }
    },
    getDashboardStatistics: async ({ commit, state }, { campaignId }) => {
        try {
            commit("SET_RECIPIENT_STATS_LOADING", true)
            const res = await EmailCampaigns.getCampaignStatistics(campaignId)
            res.inboxDashboard.statusCounts.forEach((item) => {
                state.inboxData.labels.forEach((l, lIdx) => {
                    if (item.value.toLowerCase() === l.toLowerCase()) {
                        state.inboxData.datasets[0].data.splice(
                            lIdx,
                            1,
                            item.count
                        )
                    }
                })
            })
            state.totalInboxes = res.inboxDashboard.totalCount
            res.recipientDashboard.statusCounts.forEach((e) => {
                state.recipientData.forEach((f) => {
                    if (
                        e.value.toLocaleLowerCase() ===
                        f.label.toLocaleLowerCase()
                    ) {
                        f.data = e.count
                    }
                })
            })
        } catch (error) {
            console.error(error)
        } finally {
            commit("SET_RECIPIENT_STATS_LOADING", false)
        }
    },
    setChartData: ({ state }, data: ICreateCampaignResponse) => {
        // Weird Logic need to optimize the code
        state.totalDatasets.forEach((e) => {
            e.datasets[1].data = []
            e.datasets[1].data.push(data.recipientsCount)
            if (e.label === "Delivered") {
                e.total = data.stats?.uniqueDelivers
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueDelivers)
            }
            if (e.label === "Clicks") {
                e.total = data.stats?.uniqueClicks
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueClicks)
            }
            if (e.label === "Opens") {
                e.total = data.stats?.uniqueOpens
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueOpens)
            }
            if (e.label === "Replied") {
                e.total = data.stats?.uniqueReplies
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueReplies)
            }
            if (e.label === "Undelivered") {
                e.total = data.stats?.uniqueBounces
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueBounces)
            }
        })
        state.basedOnDelivered.forEach((e) => {
            e.datasets[1].data = []
            e.datasets[1].data.push(data.recipientsCount)
            if (e.label === "Delivered") {
                e.total = data.stats?.uniqueDelivers
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueDelivers)
            }
            if (e.label === "Clicks") {
                e.total = data.stats?.uniqueClicks
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueClicks)
            }
            if (e.label === "Opens") {
                e.total = data.stats?.uniqueOpens
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueOpens)
            }
            if (e.label === "Replied") {
                e.total = data.stats?.uniqueReplies
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueReplies)
            }
        })
        state.basedOnOpens.forEach((e) => {
            e.datasets[1].data = []
            e.datasets[1].data.push(data.recipientsCount)
            if (e.label === "Opens") {
                e.total = data.stats?.uniqueOpens
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueOpens)
            }
            if (e.label === "Clicks") {
                e.total = data.stats?.uniqueClicks
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueClicks)
            }
            if (e.label === "Replied") {
                e.total = data.stats?.uniqueReplies
                e.datasets[0].data = []
                e.datasets[0].data.push(data.stats?.uniqueReplies)
            }
        })
    }
}

export default actions
