import { GetterTree } from "vuex"
import { BaseState as RootState } from "@/store/Types/BaseState"
import { BaseState } from "./BaseState"

const getters: GetterTree<BaseState, RootState> = {
    getStageOptions(state) {
        return state.stageOptions
    },
    getInboxOptions(state) {
        return state.inboxOptions
    },
    getRecipientStatusOptions(state) {
        return state.recipientStatus
    },
    getRecipientInterestStatus(state) {
        return state.interestStatus
    },
    getCampaignMessages(state) {
        return state.campaignMessages
    },
    getSequences(state) {
        return state.sequences
    },
    getRecipients(state) {
        return state.recipients
    },
    getInboxes: (state) => state.inboxes,
    getTargetInboxes: (state) => {
        return state.inboxes?.data.filter((e) => e.status === "active")
    },
    getCampaign: (state) => {
        return state.campaign
    },
    // Charts
    totalDatasets: (state) => {
        return state.totalDatasets
    },
    basedOnOpens: (state) => {
        return state.basedOnOpens
    },
    basedOnDelivered: (state) => {
        return state.basedOnDelivered
    },
    recipientData: (state) => {
        return state.recipientData
    },
    inboxData: (state) => {
        return state.inboxData
    },
    totalInboxes: (state) => state.totalInboxes,
    totalRecipients: (state) => state.campaign?.recipientsCount,
    // Loading getters
    inboxOptionLoading(state) {
        return state.inboxOptionsLoading
    },
    stageOptionsLoading(state) {
        return state.stageOptionsLoading
    },
    recipientStatusLoading(state) {
        return state.recipientStatusLoading
    },
    campaignMessageLoading(state) {
        return state.campaignMessageLoading
    },
    recipientLoading(state) {
        return state.recipientLoading
    },
    inboxLoading(state) {
        return state.inboxLoading
    },
    campaignLoading(state) {
        return state.campaignLoading
    },
    recipientStatsLoading: (state) => state.recipientStatsLoading
}

export default getters
