/* eslint-disable no-console */

import { register } from "register-service-worker"
import env from "@/env"

if (env.NODE_ENV === "production" || env.NODE_ENV === "staging") {
    register(`${(window as any).CONFIG.VUE_APP_BASE_URL}/service-worker.js`, {
        ready() {
            console.log(
                "App is being served from cache by a service worker.\n" +
                    "For more details, visit https://goo.gl/AFskqB"
            )
        },
        registered(registration: ServiceWorkerRegistration) {
            console.log("Service worker has been registered.")
            setInterval(() => {
                registration.update()
            }, 15000)
        },
        cached() {
            console.log("Content has been cached for offline use.")
        },
        updatefound() {
            console.log("New content is downloading.")
        },
        updated(registration: ServiceWorkerRegistration) {
            console.log("New content is available; please refresh.")
            const event = new CustomEvent("swUpdated", { detail: registration })
            document.dispatchEvent(event)
        },
        offline() {
            console.log(
                "No internet connection found. App is running in offline mode."
            )
            document.dispatchEvent(new Event("offline"))
        },
        error(error) {
            console.error("Error during service worker registration:", error)
        }
    })
}
