import axios from "axios"
import { getBearerToken } from "@/helpers/localStorage"
import Nprogress from "nprogress"

const Axios = axios.create({
    baseURL: (window as any).CONFIG.VUE_APP_BACKEND_API_URL,
    timeout: +(window as any).CONFIG.VUE_APP_HTTP_REQUEST_TIMEOUT,
    headers: {
        "Content-Type": "application/json"
    }
})

const authInterceptor = (config: any) => {
    Nprogress.start()
    config.headers["Authorization"] = `Bearer ${getBearerToken()}`
    return config
}

Axios.interceptors.request.use(authInterceptor)
Axios.interceptors.response.use((res) => {
    Nprogress.done()
    return res
})

export default Axios
