import { Axios } from "@/plugins/NotificationService"
import { TNotification } from "./types"
import { Constants } from "./constants"
import { getErrorMessage } from "@/helpers"

export default class NotificationService {
    static async getNotificationCount(): Promise<{ count: number }> {
        try {
            const { data } = await Axios.get(Constants.NOTIFICATIONS, {
                params: {
                    size: 0
                }
            })
            return data
        } catch (error) {
            console.log(error)
            throw new Error(getErrorMessage(error))
        }
    }
    static async getNotifications(lastKey?: string): Promise<TNotification> {
        try {
            const { data } = await Axios.get(Constants.NOTIFICATIONS, {
                params: {
                    lastKey
                }
            })
            return data
        } catch (error) {
            console.log(error)
            throw new Error(getErrorMessage(error))
        }
    }
    static async markAsRead(id: string): Promise<boolean> {
        try {
            const { data } = await Axios.put(Constants.NOTIFICATIONS + `/${id}`)
            return data
        } catch (error) {
            console.log(error)
            throw new Error(getErrorMessage(error))
        }
    }
    static async readAll(): Promise<boolean> {
        try {
            const { status } = await Axios.put(Constants.NOTIFICATIONS)
            return !!status
        } catch (error) {
            console.log(error)
            throw new Error(getErrorMessage(error))
        }
    }
}
