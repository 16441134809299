import {
    checkEmailAuth,
    isAuthenticated,
    fetchUserDetails
} from "@/middleware/Auth"
import store from "@/store"
import Vue from "vue"
import VueRouter from "vue-router"
import { routes } from "./routes"
import Nprogress from "nprogress"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0, behavior: "smooth" }
        }
    }
})
router.beforeEach(async (to, from, next) => {
    if (to.matched.some((e) => e.meta.requireAuth)) {
        await checkEmailAuth(to, next)
        try {
            await fetchUserDetails()
            const isAuthenticatedVal = await isAuthenticated()
            if (isAuthenticatedVal) {
                if (store.state.BASE_USER?.hadSubscribed) {
                    next()
                } else {
                    router.replace("/plans").catch(console.log)
                }
            } else {
                next("/account/logout")
            }
        } catch (error) {
            console.log(error)
            next("/account/logout")
        }
    } else {
        next()
    }
})
// ...

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        Nprogress.start()
    }
    next()
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    Nprogress.done()
})

export default router
