import vuetify from "@/plugins/vuetify"
import { computed, ComputedRef } from "vue"

type TResponsive = {
    /**
     * isMobile
     * use this variable to check if it is a mobile screen e.g : sm / xs
     */
    isMobile: ComputedRef<boolean>
    /**
     * isTablet
     * use this variable to check if it is a mobile screen e.g : lgAndDown
     */
    isTablet: ComputedRef<boolean>
    /**
     * isDesktop
     * use this variable to check if it is a desktop screen e.g : xl / lg
     */
    isDesktop: ComputedRef<boolean>
    /**
     * isDesktop
     * use this variable to check if it is a large screen e.g : lg and above
     */
    isLargeScreen: ComputedRef<boolean>
}

export const useResponsive = (): TResponsive => {
    const isMobile = computed(() => {
        return vuetify.framework.breakpoint.name === "xs"
    })
    const isTablet = computed(() => {
        return vuetify.framework.breakpoint.smAndDown
    })
    const isDesktop = computed(() => {
        return (
            vuetify.framework.breakpoint.name === "xl" ||
            vuetify.framework.breakpoint.name === "lg" ||
            vuetify.framework.breakpoint.mdAndUp
        )
    })

    const isLargeScreen = computed(() => {
        return vuetify.framework.breakpoint.lgAndUp
    })

    return { isMobile, isTablet, isDesktop, isLargeScreen }
}
