<script setup lang="ts">
import { ref, watch } from "vue"
import store from "@/store"
import { debounce, goBack } from "@/helpers/index"
import { useValidation } from "@/composables/validation"
import ProndoModal from "@/components/prondo/modals/Modal.vue"
import plusIcon from "@/assets/prondo/plus-icon.svg"
import { useRouter, useRoute } from "vue2-helpers/vue-router"
import { WorkSpaceService } from "@/services/workspace/ApiService"
import { TSnakType } from "@/helpers/types"
import * as Sentry from "@sentry/vue"

const router = useRouter()
const route = useRoute()
const isOpen = ref(true)
const isValid = ref(false)
const isLoading = ref(false)
const workspaceName = ref("")
const form = ref<any>()
const emit = defineEmits(["updated"])
const prop = defineProps({
    isEdit: {
        type: Boolean,
        default: false
    }
})

const { requiredRule, maximumCharacter } = useValidation()
const createWorkspace = async () => {
    if (!workspaceName.value.trim().length) {
        return
    }
    if (store.state.isWorkspaceExist) {
        isLoading.value = true
        if (prop.isEdit) {
            return updateWorkspace()
        }
        try {
            const transaction = Sentry.startTransaction({
                op: "task",
                name: "Workspace/Workspace Creation"
            })
            Sentry.getCurrentHub().configureScope((scope) =>
                scope.setSpan(transaction)
            )
            await WorkSpaceService.createWorkspace({
                name: workspaceName.value.trim()
            })
            isLoading.value = false
            transaction.finish()
            // store.dispatch("getWorkspaceList")
            emit("updated")
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: "Workspace Created successfully"
            })
            router.back()
            workspaceName.value = ""
        } catch (error) {
            if (error instanceof Error) {
                store.commit("SET_SNAKBAR", {
                    show: true,
                    type: TSnakType.ERROR,
                    message: error.message
                })
            }
        } finally {
            workspaceName.value = ""
            form.value.reset()
            isLoading.value = false
        }
    }
}
const viewWorkspace = async () => {
    isLoading.value = true
    const response = await WorkSpaceService.viewWorkspace(route.params.id)
    workspaceName.value = response.name
    isLoading.value = false
}
const updateWorkspace = async () => {
    try {
        await WorkSpaceService.updateWorkspace(
            {
                name: workspaceName.value.trim()
            },
            route.params.id
        )

        emit("updated")
        router.back()
        workspaceName.value = ""
        store.commit("SET_SNAKBAR", {
            show: true,
            type: TSnakType.SUCCESS,
            message: "Workspace Updated successfully"
        })
    } catch (error) {
        if (error instanceof Error) {
            store.commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.ERROR,
                message: error.message
            })
        }
    } finally {
        isLoading.value = false
    }
}
const checkWorkspaceExist = debounce(() => {
    if (workspaceName.value?.trim()) {
        store.dispatch("checkWorkspaceExist", workspaceName.value.trim())
    }
})
const getErrorMessage = () => {
    if (!store.state.isWorkspaceExist) return ["Workspace Name is taken"]
    return []
}
watch(
    () => route.params.id,
    (v) => {
        if (prop.isEdit && v) {
            viewWorkspace()
        }
    },
    { deep: true, immediate: true }
)
</script>
<template>
    <div>
        <ProndoModal
            max-width="600px"
            :value="isOpen"
            show-close
            @close="goBack"
        >
            <v-form
                v-model="isValid"
                ref="form"
                @submit.prevent="createWorkspace"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5 font-weight-bold">{{
                            prop.isEdit
                                ? "Update Workspace"
                                : "Create Workspace"
                        }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        :error-messages="getErrorMessage()"
                                        v-model.trim="workspaceName"
                                        :loading="
                                            store.state.isWorkspaceChecking
                                        "
                                        label="Workspace Name*"
                                        @input="checkWorkspaceExist"
                                        :rules="[
                                            ...requiredRule('Workspace name'),
                                            maximumCharacter(
                                                40,
                                                'Workspace Name'
                                            )
                                        ]"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-start pa-5">
                        <v-btn
                            @click="createWorkspace"
                            max-width="222px"
                            height="40px"
                            depressed
                            rounded
                            class="white--text outlined-orange flex-grow-1"
                            :disabled="!isValid"
                            :loading="isLoading"
                        >
                            <plusIcon
                                width="25px"
                                height="25px"
                                v-if="!prop.isEdit"
                            />
                            {{
                                prop.isEdit
                                    ? "Update workspace"
                                    : " Create Workspace"
                            }}
                        </v-btn>
                        <v-btn @click="goBack" class="cancel-btn" depressed>
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </ProndoModal>
    </div>
</template>
