import Vue from "vue"
import Vuex from "vuex"
import { TSnakType, SnakbarMode } from "@/helpers/types"
import { TMeta } from "@/services/types/meta"
import { createStore } from "vue2-helpers/vuex"
import { BaseState } from "./Types/BaseState"
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import { UserType } from "./Types/Frontegg"
import { campaignStore } from "./modules/campaign"
import { databaseStore } from "./modules/databases"
Vue.use(Vuex)

export default createStore<BaseState>({
    state() {
        return {
            token: "",
            isRedirecting: false,
            user: {} as UserType,
            notificationCount: 0,
            BASE_USER: undefined,
            isLoading: false,
            workspaceList: {
                data: [],
                meta: {} as TMeta
            },
            isWorkspaceExist: true,
            workspace: {
                workflowsCount: 0,
                updatedAt: "",
                name: "loading...",
                createdAt: "",
                recordsCount: 0,
                id: "",
                isDeletable: false,
                isSelected: false,
                isArchived: false
            },
            list: {
                name: "loading..."
            },
            snakBar: {
                show: false,
                message: "",
                type: TSnakType.SUCCESS,
                mode: SnakbarMode.NORMAL,
                timeout: 3000
            },
            isWorkspaceChecking: false,
            catalogues: [],
            Tenants: []
        }
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
    modules: {
        campaignStore: campaignStore,
        databaseStore: databaseStore
    }
})
