export const EventSourceMessages = {
    WORKSPACE_IMPORT_PROGRESS: "Database import in progress",
    WORKSPACE_EXPORT_PROGRESS: "Database export in progress",
    CAMPAIGN_STATUS_UPDATED: "Campaign status updated.",
    INBOX_UNAUTHORIZED: "Mailbox authorization lost. Please re-authorize.",
    INBOX_BLOCKED: "Mailbox blocked or restricted by Google.",
    WORKSPACE_IMPORT_COMPLETED: "Import Successful.",
    WORKSPACE_IMPORT_FAILED:
        "Import Failed. Please check the data and try again.",
    WORKSPACE_EXPORT_COMPLETED: "Export Successful.",
    WORKSPACE_EXPORT_FAILED: "Export failed. Please try again later.",
    WORKSPACE_EMAILS_FIND_COMPLETED: "Email find completed!",
    WORKSPACE_EMAILS_FIND_FAILED: "Failed! No emails found.",
    WORKSPACE_EMAILS_VERIFY_COMPLETED: "Email verify completed!",
    WORKSPACE_EMAILS_VERIFY_FAILED: "Unable to verify email. Please try again."
} as const
