import { Module } from "vuex"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import { BaseState } from "./BaseState"
import { BaseState as RootState } from "@/store/Types/BaseState"

export const databaseStore: Module<BaseState, RootState> = {
    namespaced: true,
    state: {
        databases: undefined,
        databaseLoading: false,
        totalDatabases: 0,
        databaseUpdateAvailable: false
    },
    actions: actions,
    mutations: mutations,
    getters: getters
}
