<template>
    <div
        class="custom-progress"
        :class="`custom-progress--${type?.toLocaleLowerCase()}`"
    >
        <div class="custom-progress__title" v-if="snakbarType === 'static'">
            <div
                class="custom-progress__icon-container"
                :class="
                    isCompleted
                        ? `custom-progress__icon-container--${type.toLocaleLowerCase()}`
                        : ''
                "
            >
                <ErrorIcon
                    v-if="type?.toLowerCase() === 'error'"
                    width="14px"
                />
                <CheckIcon width="14px" v-else-if="isCompleted" />
                <LottieAnimation
                    v-else
                    path="lottie/clock.json"
                    :loop="true"
                    :autoPlay="true"
                    :speed="2"
                    :width="24"
                    :height="24"
                />
            </div>
            <span> {{ title }}</span>
        </div>
        <template v-else>
            <template v-if="getProgress === 100">
                <div class="custom-progress__title">
                    <div
                        class="custom-progress__icon-container custom-progress__icon-container--success"
                    >
                        <CheckIcon width="14px" />
                    </div>
                    <span> {{ title }}</span>
                </div>
            </template>
            <template v-else>
                <div class="custom-progress__title" style="gap: 5px">
                    <div class="custom-progress__icon-container">
                        <ErrorIcon
                            v-if="type?.toLowerCase() === 'error'"
                            width="14px"
                        />
                        <LottieAnimation
                            v-else
                            path="lottie/clock.json"
                            :loop="true"
                            :autoPlay="true"
                            :speed="2"
                            :width="20"
                            :height="20"
                        />
                    </div>
                    <span> {{ title }}</span>
                </div>
                <div class="custom-progress__bar">
                    <progress
                        :value="getProgress"
                        max="100"
                        id="progress-bar"
                    ></progress>
                </div>
                <div id="progress-label" class="custom-progress__label">
                    <span v-if="type?.toLowerCase() === 'error'">Error</span>
                    <template v-else>
                        <span>{{ getProgress }}%</span>
                        <span>{{
                            getProgress
                                ? getProgress === 100
                                    ? "competed!"
                                    : "progress.."
                                : "starting..."
                        }}</span>
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
import { TYPE } from "vue-toastification"
import ErrorIcon from "@/assets/prondo/error-outline.svg"
import CheckIcon from "@/assets/prondo/checked-outline.svg"

const props = defineProps<{
    title: string
    progress?: {
        total: number
        processed: number
        succeeded: number
    }
    isCompleted?: boolean
    snakbarType?: "static" | "dynamic"
    type: keyof typeof TYPE
}>()
const getProgress = computed(() => {
    if (props.progress?.total === 0) return 0
    return (
        props.progress &&
        Math.round((props.progress?.processed / props.progress?.total) * 100)
    )
})
</script>

<style scoped lang="scss">
.custom-progress {
    position: relative;
    width: 100%;

    .progress {
        width: 100%;
        height: 20px;
    }

    &__label {
        color: #b0b0d3;
        font-size: 12px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        column-gap: 4px;
    }

    &__title {
        font-size: 16px;
        display: flex;
        align-items: center;
        column-gap: 12px;
    }

    &__bar {
        margin-top: 12px;
    }

    &__icon-container {
        display: inline-flex;
        padding: 4px;
        border-radius: 20px;

        &--success {
            background-color: rgba(10, 148, 58, 0.36) !important;
        }
    }

    &--success {
        .custom-progress__icon-container {
            background-color: rgba(252, 127, 59, 0.24);
        }

        progress {
            width: 100%;
            height: 10px;
            border: 2px solid transparent;
            border-radius: 10px;
        }

        progress::-webkit-progress-bar {
            background-color: #60648c;
            border-radius: 10px;
        }

        progress::-webkit-progress-value {
            background-color: #0a943a;
            border-radius: 10px;
        }
    }

    &--error {
        .custom-progress__icon-container {
            background-color: rgba(255, 20, 20, 0.2);
        }

        progress {
            width: 100%;
            height: 10px;
            border: 2px solid transparent;
            border-radius: 10px;
        }

        progress::-webkit-progress-bar {
            background-color: #26283f;
            border-radius: 10px;
        }

        progress::-webkit-progress-value {
            background-color: #60648c;
            border-radius: 10px;
        }
    }
}
</style>
