import router from "@/router"
import store from "@/store"
import axios from "axios"
import { computed } from "vue"
import { URL_REGX, DOMAIN_REGEX } from "@/composables/validation"
export const getWorkspaceId = (): string => {
    return router.currentRoute.params.workspace_id
}
export const getTaskId = (): string => {
    return router.currentRoute.params.task_id
}
// eslint-disable-next-line
export const getErrorMessage = (err: any): string => {
    if (axios.isAxiosError(err)) {
        const error: errorType = err.response?.data as errorType
        if (Array.isArray(error.message)) {
            const messages = error.message.map((m) => m.constraints)
            const message = messages.map((m) => {
                return Object.values(m)
            })
            return message.join("\n")
        }
        const errorMessage = String(error.message)
        return errorMessage
    } else {
        return "Error Occured"
    }
}

export const getReturnURL = (): string => {
    if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
    ) {
        return `https://uat.prondo.co${window.location.pathname}`
    } else return window.location.href
}

type errorType = {
    error: string
    message: string
    statusCode: number
}

export const debounce = (cb: (...args: unknown[]) => void, delay = 1000) => {
    let timeOut: ReturnType<typeof setTimeout>
    return (...args: []) => {
        clearTimeout(timeOut)
        timeOut = setTimeout(() => {
            cb(...args)
        }, delay)
    }
}

export const saveData = (function () {
    const a = document.createElement("a")
    document.body.appendChild(a)
    return function (data: File, fileName: string) {
        const blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
    }
})()

export const capitalize = (str: string, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
        match.toUpperCase()
    )

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes)
        return {
            formatSize: "0 Bytes",
            rowSize: 0,
            type: ""
        }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    const rowSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))

    return {
        formatSize: `${rowSize} ${sizes[i]}`,
        rowSize,
        type: sizes[i]
    }
}

export function numberFormatter(num: number, digits: number) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value
        })
    return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0"
}

export function removeEmptyObjProperty(obj: object) {
    return Object.fromEntries(
        Object.entries(obj).filter(([, v]) => v !== null && v !== "")
    )
}

export const isAdmin = computed(() => {
    return store.state.user?.roles[0]?.name === "Admin"
})
export const isMember = computed(() => {
    return store.state.user?.roles[0]?.name === "Member"
})

export const getWebPlaceholder = () =>
    require("/src/assets/web-placeholder.png")

export const goBack = () => {
    const currentRoute = router.currentRoute.fullPath
    const routePath = currentRoute.split("/").filter((e) => e)
    const excludedRoutes = routePath.slice(0, -2)
    if (currentRoute.includes("?")) {
        const path = `/${
            routePath.length <= 2
                ? routePath[0]
                : `${excludedRoutes?.join("/")}`
        }`
        router.push({
            path,
            params: {}
        })
    } else {
        window.history.length > 1 ? router.go(-1) : router.push("/")
    }
}

export const validDomain = (url: string) => {
    if (!url) return true
    return DOMAIN_REGEX.test(url) || URL_REGX.test(url)
}
export function formatString(inputString: string) {
    if (typeof inputString !== "string") {
        throw new Error("Input must be a string")
    }
    const result = inputString.replace(/([A-Z])/g, " $1")
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

export const injectScriptCdn = (link: string, id: string) => {
    if (document.getElementById(id)) return
    const scriptTag = document.createElement("script")
    scriptTag.src = link
    scriptTag.id = id
    document.getElementsByTagName("head")[0].appendChild(scriptTag)
}
export const removeScriptCdn = (id: string) => {
    const script = document.getElementById(id)
    script?.remove()
}
