import { ActionTree } from "vuex"
import { BaseState } from "../Types/BaseState"
import { WorkSpaceService } from "@/services/workspace/ApiService"
import { CataloguesService } from "@/services/catalogues/ApiService"
import { TSnakType } from "@/helpers/types"
import { ListService } from "@/services/list/ApiService"
import { TMeta } from "@/services/types/meta"
import NotificationService from "@/services/notifications"
import GeneralService from "@/services/General"
import router from "@/router/index"

const actions: ActionTree<BaseState, BaseState> = {
    getTenants({ commit }) {
        GeneralService.getTenants().then((data) => {
            commit("SET_TENANTS", data)
        })
    },
    setToken({ commit }) {
        if (localStorage.getItem("token")) {
            commit("SET_TOKEN", localStorage.getItem("token"))
        }
    },
    setUser({ commit }) {
        if (localStorage.getItem("user")) {
            commit("SET_USER", JSON.parse(localStorage.getItem("user") || ""))
        }
    },
    setEmailToken({ commit }) {
        if (localStorage.getItem("EMAIL_TOKEN")) {
            commit(
                "EMAIL_TOKEN",
                JSON.parse(localStorage.getItem("EMAIL_TOKEN") || "")
            )
        }
    },
    async checkWorkspaceExist({ state, commit }, payload) {
        commit("SET_WORKSPACE_CHECKING", true)
        const { result } = await WorkSpaceService.checkWorkspacExist({
            value: payload
        })
        commit("SET_WORKSPACE_CHECKING", false)
        state.isWorkspaceExist = result
    },
    async viewWorkspace({ state, commit }, { id }) {
        commit("SET_LOADING", true)
        const response = await WorkSpaceService.viewWorkspace(id)
        state.workspace = response
        commit("SET_LOADING", false)
    },
    async viewList({ state, commit }, { id }) {
        commit("SET_LOADING", true)
        const response = await ListService.getListById(id)
        state.list.name = response.name
        commit("SET_LOADING", false)
    },
    async createWorkspace({ state, dispatch }, payload) {
        if (state.isWorkspaceExist) {
            await WorkSpaceService.createWorkspace({
                name: payload
            })
            dispatch("getWorkspaceList")
        }
    },
    async updateWorkspace({ state, dispatch, commit }, { name, id }) {
        if (state.isWorkspaceExist) {
            await WorkSpaceService.updateWorkspace(
                {
                    name
                },
                id
            )
            commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: "Workspace updaed successfully"
            })
            dispatch("getWorkspaceList")
        }
    },
    async deleteWorkspace({ dispatch, commit }, id) {
        try {
            await WorkSpaceService.deleteWorkspace(id)
            commit("SET_SNAKBAR", {
                show: true,
                type: TSnakType.SUCCESS,
                message: "Workspace deleted successfully"
            })
            dispatch("getWorkspaceList")
        } catch (error) {
            if (error instanceof Error) {
                commit("SET_SNAKBAR", {
                    show: true,
                    type: TSnakType.ERROR,
                    message: error.message
                })
            }
        }
    },
    async getWorkspaceList(
        { commit },
        payload: {
            meta?: TMeta
            isArchived?: boolean
            callback?: () => void
            pageNum: number
            searchText: string
        }
    ) {
        commit("SET_LOADING", true)
        try {
            const response = await WorkSpaceService.getWorkspaceList(
                payload.searchText,
                payload.meta,
                payload.pageNum,
                payload.isArchived
            )
            commit("SET_WORKSPACE_LIST", response)
        } finally {
            commit("SET_LOADING", false)
            payload.callback && payload.callback()
        }
    },
    async getCatalogues({ commit }) {
        commit("SET_LOADING", true)
        const response = await CataloguesService.getCatalogues()
        commit("SET_CATALOGUES", response)
        commit("SET_LOADING", false)
    },
    async getNotificationCount({ state }) {
        const data = await NotificationService.getNotificationCount()
        state.notificationCount = data.count
    },
    async getAccountDetails({ state }) {
        const data = await GeneralService.getUserDetails()
        state.BASE_USER = data
    },
    openCustomerPortal: () => {
        GeneralService.getCustomerPortal()
            .then((data) => {
                if (data.result) {
                    window.location.href = data.result
                } else {
                    router.push({ path: "/plans" })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    },
    cancelSubscription({ commit, dispatch }, callback) {
        commit("SET_LOADING", true)
        GeneralService.cancelSubscription()
            .then((data) => {
                callback({ error: null, data: data })
                dispatch("getAccountDetails")
            })
            .catch((e) => {
                console.log(e)
                callback({ error: e, data: null })
            })
            .finally(() => {
                commit("SET_LOADING", false)
            })
    },
    resumeSubscription({ commit, dispatch }, callback) {
        commit("SET_LOADING", true)
        GeneralService.resumeSubscription()
            .then((data) => {
                callback({ error: null, data: data })
                dispatch("getAccountDetails")
            })
            .catch((e) => {
                console.log(e)
                callback({ error: e, data: null })
            })
            .finally(() => {
                commit("SET_LOADING", false)
            })
    }
}

export default actions
