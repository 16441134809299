import { MutationTree } from "vuex"
import { BaseState } from "./BaseState"

const mutations: MutationTree<BaseState> = {
    SET_STAGE_OPTIONS(state, payload) {
        state.stageOptions = payload
    },
    SET_INBOX_OPTIONS(state, payload) {
        state.inboxOptions = payload
    },
    SET_CAMPAIGN_MESSAGES(state, payload) {
        state.campaignMessages = payload
    },
    SET_SEQUENCE_MESSAGES(state, payload) {
        state.sequences = payload
    },
    SET_RECIPIENTS(state, payload) {
        state.recipients = payload
    },
    SET_INBOXES(state, payload) {
        state.inboxes = payload
    },
    SET_CAMPAIGN(state, payload) {
        state.campaign = payload
    },
    // Loading Setters
    SET_INBOX_OPTION_LOADING(state, payload) {
        state.inboxOptionsLoading = payload
    },
    SET_INBOX_LOADING(state, payload) {
        state.inboxLoading = payload
    },
    SET_STAGE_OPTION_LOADING(state, payload) {
        state.stageOptionsLoading = payload
    },
    SET_CAMPAIGN_MESSAGES_LOADING(state, payload) {
        state.campaignMessageLoading = payload
    },
    SET_RECIPIENT_LOADING(state, payload) {
        state.recipientLoading = payload
    },
    SET_CAMPAIGN_LOADING(state, payload) {
        state.campaignLoading = payload
    },
    SET_RECIPIENT_STATS_LOADING(state, payload) {
        state.recipientStatsLoading = payload
    }
}
export default mutations
