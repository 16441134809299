export class SETTINGS {
    static readonly DOMAIN = "/accounts/domain"
    static readonly CUSTOMER_PORTAL = "/subscriptions/managementUrl"
    static readonly CANCEL_SUBSCRIPTION = "/subscriptions/cancel"
    static readonly RESUME_SUBSCRIPTION = "/subscriptions/resume"
    static readonly INBOX_CUSTOM_DOMAIN = "/domain"
    static readonly USERS_ME = "/accounts/me"
    static readonly Tenants = "/accounts/me/tenants"
    static readonly IBOXES = "/inboxes"
    static readonly DAILY_QUOTA = "/dailyQuota"
    static readonly SIGNATURE = "/signature"
}
