import { DirectiveOptions } from "vue"

// const invalidChars = ["-", "+", "e"]

function onlyNumberAllowed(e: KeyboardEvent) {
    const key = Number(e.key)
    if (isNaN(key)) return e.preventDefault()
    if (!isFinite(key)) e.preventDefault()
}
function preventSpecialCharacter(event: KeyboardEvent) {
    const regex = new RegExp("^[a-zA-Z]+$")
    const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
    )
    const keysToEscape = ["Backspace", "Tab", "ArrowLeft", "ArrowRight"]
    if (keysToEscape.includes(event.key)) {
        return true
    } else if (!regex.test(key)) {
        event.preventDefault()
    }
}

export const onlyNumber: DirectiveOptions = {
    bind: (el) => {
        el.addEventListener("keypress", onlyNumberAllowed)
    },
    unbind(el) {
        el.removeEventListener("keypress", onlyNumberAllowed)
    }
}
export const onlyString: DirectiveOptions = {
    bind: (el) => {
        el.addEventListener("keydown", preventSpecialCharacter)
    },
    unbind(el) {
        el.removeEventListener("keydown", preventSpecialCharacter)
    }
}
