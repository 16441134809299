import { Axios } from "@/plugins/axios/EmailService"
import axios from "@/plugins/axios"
import { getErrorMessage } from "@/helpers"
import { DASHBOARD } from "./constants"
import { DashboardStatType, EmailStatType } from "./types"

export default class DashboardService {
    static async getDashboardStatsForEmailService(): Promise<EmailStatType> {
        try {
            const { data } = await Axios.get(DASHBOARD.DASHBOARD)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getDashboardStats(): Promise<DashboardStatType> {
        try {
            const { data } = await axios.get(DASHBOARD.DASHBOARD)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
