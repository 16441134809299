import { TCatalogue } from "@/services/catalogues/types"
import { TworkspaceList } from "@/services/workspace/types"
import { BaseState } from "../Types/BaseState"
import { MutationTree } from "vuex"
import { USER_INFO } from "@/services/General/types"
import { UserType } from "../Types/Frontegg"
import { SnakbarOptions } from "@/store/Types/BaseState"

const mutations: MutationTree<BaseState> = {
    SET_TOKEN(state, token: string) {
        state.token = token
    },
    SET_USER(state, user: UserType) {
        state.user = user
    },
    SET_LOADING(state, payload: boolean) {
        state.isLoading = payload
    },
    SET_WORKSPACE_CHECKING(state, payload: boolean) {
        state.isWorkspaceChecking = payload
    },
    SET_WORKSPACE_LIST(state, payload: TworkspaceList) {
        state.workspaceList = payload
    },
    SET_CATALOGUES(state, payload: Array<TCatalogue>) {
        state.catalogues = payload
    },
    SET_SNAKBAR(state, payload: SnakbarOptions) {
        state.snakBar = payload
    },
    SET_USER_INFO(state, payload: USER_INFO) {
        state.BASE_USER = payload
    },
    SET_TENANTS(state, payload) {
        state.Tenants = payload
    }
}
export default mutations
