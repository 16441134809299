import { injectScriptCdn, removeScriptCdn } from "@/helpers"
import { onMounted, onUnmounted } from "vue"

export const useCdnScript = (cdnUrl: string, id: string) => {
    onMounted(() => {
        injectScriptCdn(cdnUrl, id)
    })
    onUnmounted(() => {
        removeScriptCdn(id)
    })
}
