import { AUTH_CONSENT } from "./ApiConstant"
import { Axios } from "@/plugins/axios/EmailService"
import {
    ACCESS_TOKEN,
    INBOX_LIST,
    InboxParams,
    TAUTH_CONSENT,
    TEXCHANGE_TOKEN,
    Taccount
} from "./types"
import { getErrorMessage } from "@/helpers"
import router from "@/router"
import { TMeta } from "../types/meta"
import store from "@/store"
import axios from "@/plugins/axios"
export class GMAIL_INBOXES {
    // This will be called if we don't have any metadata
    static async createAccount(): Promise<Taccount> {
        try {
            const { data } = await Axios.post(AUTH_CONSENT.ACCOUNT)
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async CREATE_ACCESS_TOKEN(accountId: string): Promise<ACCESS_TOKEN> {
        try {
            const { data } = await Axios.post(
                AUTH_CONSENT.GLOBAL_ACCESS_TOKEN,
                {
                    accountId: accountId
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async oauthConsent(
        args: { email?: string } = {}
    ): Promise<TAUTH_CONSENT> {
        try {
            const state = JSON.stringify({
                redirectionUrl: window.location.pathname
            })
            const { data } = await Axios.get(AUTH_CONSENT.AUTH, {
                params: {
                    redirectUri: window.location.origin,
                    ...args,
                    state: encodeURI(state)
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async exchangeToken(
        code: string,
        redirectionUrl: string
    ): Promise<TEXCHANGE_TOKEN> {
        try {
            store.state.isRedirecting = true
            const { data } = await Axios.get(AUTH_CONSENT.TOKEN, {
                params: {
                    code: code,
                    redirectUri: window.location.origin
                }
            })
            // write an method to get accessToken from storage
            await this.signUpInbox(data)
            await router.push(
                redirectionUrl || "/account-settings/mailbox-settings"
            )
            store.state.isRedirecting = false
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async signUpInbox(body: TEXCHANGE_TOKEN): Promise<ACCESS_TOKEN> {
        const { data } = await Axios.post(AUTH_CONSENT.SIGN_UP_INBOX, {
            ...body
        })
        return data
    }
    static async getInboxes(params: InboxParams): Promise<INBOX_LIST> {
        try {
            const { data } = await Axios.get(AUTH_CONSENT.SIGN_UP_INBOX, {
                params: {
                    pageNum: params.pageNum,
                    ...params.meta,
                    campaignId: params.campaignId,
                    searchText: params.searchText
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    static async getInboxOptions(
        meta?: Partial<TMeta>,
        campaignId?: string
    ): Promise<INBOX_LIST> {
        try {
            const { data } = await Axios.get(AUTH_CONSENT.SIGN_UP_INBOX, {
                params: {
                    ...meta,
                    campaignId
                }
            })
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param inboxId
     * @returns status
     *
     */
    static async updateInboxStatus(
        inboxId: string,
        status: "active" | "inactive"
    ): Promise<boolean> {
        try {
            const { data } = await Axios.patch(
                AUTH_CONSENT.SIGN_UP_INBOX + "/" + inboxId + "/status",
                {
                    value: status
                }
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param inboxId
     * @returns status
     *
     */
    static async syncInbox(inboxId: string): Promise<boolean> {
        try {
            const { data } = await Axios.post(
                AUTH_CONSENT.SIGN_UP_INBOX + "/" + inboxId + "/sync"
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add inbox to campaign
     * @param inboxId
     * @returns status
     *
     */
    static async deleteInbox(inboxId: string): Promise<boolean> {
        try {
            const { data } = await Axios.delete(
                AUTH_CONSENT.SIGN_UP_INBOX + "/" + inboxId
            )
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Add meta to account info
     * @param EMAIL_TOKEN [string]
     * @returns status
     *
     */
    static async storeMeta(EMAIL_TOKEN: string): Promise<boolean> {
        try {
            const { data } = await axios.patch("/accounts/metadata", {
                EMAIL_TOKEN
            })

            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
    /**
     * Retrive meta from account
     * @returns metaData
     *
     */
    static async retriveMeta(): Promise<any> {
        try {
            const { data } = await axios.get("/accounts/metadata")
            if (data.EMAIL_TOKEN) {
                localStorage.setItem("EMAIL_TOKEN", data.EMAIL_TOKEN)
            } else {
                const data = await this.createAccount()
                const token = await this.CREATE_ACCESS_TOKEN(data.id)
                await this.storeMeta(token.id)
                localStorage.setItem("EMAIL_TOKEN", token.id)
            }
            return data
        } catch (error) {
            throw new Error(getErrorMessage(error))
        }
    }
}
